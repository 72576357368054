export const environment = {
    production: false,
    apiRoot: 'https://stg-operation-system-be.sakuramobile.jp',
    orderFormRoot: 'https://stg-longterm-order.sakuramobile.jp',
    parentalConsentRoot: 'https://stg-parental-consent.sakuramobile.jp',
    fiberOrderRoot: 'https://stg-fiber-order.sakuramobile.jp',
    esimActivationRoot: 'https://stg-long-esim-activation.sakuramobile.jp',
    liquidEkycManager: 'https://manager-og.stg-liquid-ekyc.com/operation/check-pages/start-id/',
    operationDB: 'https://stg-operation.sakuramobile.jp'
};
