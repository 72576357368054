import { Component, ViewChild, OnInit, OnDestroy, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatCheckboxChange,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';
// import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
// import { OperationComponent } from '../../page/operation/operation.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Url } from 'src/app/constants';
// import { Subscriber } from 'rxjs';
import { LoginReply } from 'src/app/models';
import { environment } from 'src/environments/environment';

export class Contract {
  id: number;
  contract_code: string;
  status: boolean;
  start_at: Date;
  start_at_string: string;
  end_at: Date;
  end_at_string: string;
}

export class DeviceCode1 {
  id: number;
  contract_product_id: number;
  product_property_id: number;
  product_property_type_id: number;
  product_type_id: number;
  show_order: number;
  show_status: boolean;
  title: string;
  updated_by_user_id: number;
  updated_at: string;
  key: string;
  value: string;
}

export class ChargeProductItem {
  id: number;
  contract_id: number;
  description: string;
  end_at: string;
  end_at_updated_at: string;
  end_at_updated_by_user_id: number;
  // invoice_sender_key: string;
  product_type_id: number;
  return_check_date: string;
  return_checker_user_id: number;
  return_date: string;
  show_order: number;
  start_at: string;
  start_at_updated_at: string;
  start_at_updated_by_user_id: number;
  title: string;
  device_code_1: DeviceCode1;
}

export class ChargeListItem {
  id: number; // invoice_id
  invoice_month: string;
  invoice_code: string;
  contract_id: number;
  contract_code: string;
  contract_end_date: string; // for sort only
  contract: Contract;
  // user_id: number;
  // user_e_mail: string;
  // user_name_id: number;
  status: string;
  user_name: string;
  mail_send_status: number;
  mail_send_config: number;
  pay_method: string;
  demand_status: number;
  demand_send_user_id: number;
  demand_sent_at: string;
  pay_status: number;
  pay_error: string;
  price_total: number;
  price_tax: number;
  products_display_string: string;
  products: Array<ChargeProductItem>;
  showNoteUpdatedByEmail: boolean[] = [];

  is_selected: boolean;    // for user interface
  constructor() {
    this.is_selected = false;
  }
  data: {
    esims: ChargeListItem[];
    filterStatus: string;
  }
  filteredEsims: ChargeListItem[];
}

export class PayMethod {
  public key: string;
  public title: string;
}

export class DemandLevel {
  public key: string;
  public title: string;
  isSelected?: boolean;
}

export interface AuthGetReply {
  data: { is_member: boolean };
}

export interface InactiveUsersResponse {
  data: {
    esims: ChargeListItem[];
  };
}

export interface ChargeListItem {
  contract_id: number;
  start_at: string;
  contract_product_id: number;
  note: string;
  isEditing?: boolean;
  telno: string;
  id: number;
  last_esim_email_type: number;
}

@Component({
  selector: 'app-list-activate',
  templateUrl: './list-activate.component.html',
  styleUrls: ['./list-activate.component.scss']
})
export class ListActivateComponent implements OnInit {
  public root_for_pdf: string;
  public unpaid_only: boolean;
  // public invoice_month: string;
  // public selecting_pay_method: PayMethod;
  // public pay_methods: Array<PayMethod>;
  public selecting_demand_level: DemandLevel;
  public demand_levels: Array<DemandLevel>;
  // public demand_send_button_disabled: boolean;
  public charge_button_disabled: boolean;
  // public invoices: Array<ChargeListItem>;
  // public invoices_send_targets: Array<ChargeListItem>;
  // public invoice_month_error: string;
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<ChargeListItem>;
  public filter_text: string;
  public selection = new SelectionModel<ChargeListItem>(true, []);
  public current_filter: string;
  // public contract_month: string;
  public selectedDate: string | null = null;
  public auth_token: string | null = null;
  public users: any[] = [];
  public auth_reply: LoginReply;
  public showUpdated: boolean[] = [];
  public showUpdatedNote: boolean[] = [];
  public showMessage: boolean[] = [];
  public message: string = '';
  public select_send_all: boolean;
  public select_send_all_disabled: boolean;
  public select_send_all_indeterminate: boolean;
  // public sent_invoice_codes: Array<string>;
  // public sent_success_invoice_codes: Array<string>;
  // public sent_fail_invoice_codes: Array<string>;
  protected _el: HTMLElement;
  public confirm_dialog_ref: any;
  public monthly_summary: any;
  public formattedDate: string;
  public filteredEsims: ChargeListItem[];
  public searchStartDate: string = '';
  public searchEndDate: string = '';
  public maxStartDate: string = '';
  public minEndDate: string = '';
  public filteredData: any[] = [];
  public originalData: ChargeListItem[] = [];
  public search_start_error: boolean = false;
  public search_end_error: boolean = false;
  public selectedStatus: string = 'all';
  public statuses: string[];
  public urlOpdbMap: { [key: string]: string } = {};
  public telnoUrlMap: { [key: string]: string } = {};
  public kpgUrlMap: { [key: string]: string } = {};
  public isEditing = false;
  public url_telno: string;
  public url_link: string;
  public data = {
    esims: [
      { status: '問題なし' },
      { status: '問題あり' },
      { status: '' }
    ] as ChargeListItem[],
    filterStatus: '',
    statuses: {
      '問題なし': 'no_problem',
      '問題あり': 'has_problem',
      '未設定': 'no_status',
    }
  };
  constructor(
    protected el: ElementRef,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    this._el = el.nativeElement;
    this.select_send_all = false;
    this.select_send_all_disabled = true;
    this.select_send_all_indeterminate = false;
    this.statuses = [];
    // this.unpaid_only = true;
    // this.invoice_month = undefined;
    this.filter_text = '';
    // this.pay_methods = new Array<PayMethod>();
    // this.pay_methods.push({ key: 'CREDIT CARD', title: 'CREDIT CARD' });
    // this.pay_methods.push({ key: 'SMARTPIT', title: 'SMARTPIT' });
    // this.pay_methods.push({ key: 'PAYPAL', title: 'PAYPAL' });
    // this.pay_methods.push({ key: 'PONTA', title: 'PONTA' });
    // this.pay_methods.push({ key: 'GI BILL', title: 'GI BILL' });
    this.demand_levels = new Array<DemandLevel>();
    this.data_source = new MatTableDataSource<ChargeListItem>();
    this.demand_levels.push({ key: '1', title: 'Lv 1: 最近のeSIM申請について' });
    this.demand_levels.push({ key: '2', title: 'Lv 2: eSIM継続契約,解約について' });
    this.demand_levels.push({ key: '3', title: 'Lv 3: eSIM契約の解約について' });
    this.charge_button_disabled = true;
    this._el = el.nativeElement;
    this.display_columns = [
      'is_selected',
      'status',
      'contract_code',
      // 'invoice_code',
      // 'pdf',
      // 'products',
      'user_name',
      'email',
      'start_at',
      // 'pay_method',
      'telno',
      'line_pause',
      'used_packets',
      'kpg',
      'demand_level',
      'mail_level',
      // 'status_updated_at',
      // 'status_updated_by_emai',
      // 'note_updated_by_email',
      // 'note_updated_at',
      'note',
    ];
    this.filteredEsims = this.data.esims;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('copyMessage') copyMessageEl!: ElementRef;
  @ViewChild('noteInput') noteInput: ElementRef | undefined;

  ngOnInit() {
    // <<< for debug environment
    if (location.href.indexOf('localhost:', 0) > 0) {
      const parts = location.href.split(':');
      this.root_for_pdf = parts[0] + ':' + parts[1] + ':3001';
    } else {
      this.root_for_pdf = '';
    }
    // >>> for debug environment
    if (this.auth_service.isLoggingIn() !== true) {
      this.auth_service.login_after_url = window.location.href;
      location.href = '/auth/login';
      return;
    }
    this.auth_service.apiIsMemberUser('operator', this, (reply: AuthGetReply, context: any) => {
      if (reply.data.is_member === true) {
      } else {
        this.auth_service.login_after_url = window.location.href;
        location.href = '/auth/login';
      }
    });
    this.filteredEsims = this.data.esims;
    this.data_source.paginator = this.paginator;

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    this.searchStartDate = this.formatDate(yesterday);
    this.searchEndDate = this.formatDate(yesterday);
    this.maxStartDate = this.formatDate(yesterday);
    console.log("Yesterday's date:", this.formatDate(yesterday));
    console.log("Today's date:", this.formatDate(today));
  }


  ngAfterViewInit() {
    this.data_source.sort = this.sort;

    this.data_source.sortingDataAccessor = (item, property) => {
      if (property === 'mail_level') {
        return item.last_esim_email_type;
      }
      return item[property];
    };
  }


  public resetPage(): void {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    this.searchStartDate = this.formatDate(yesterday);
  }


  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }


  public getTelnoUrl(telno: number | null): string {
    if (!telno) {
      return '';
    }
    const encodeTelnoValue = encodeURIComponent(String(telno));
    return `https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/index.cgi?TYPE=1&INFO=${encodeTelnoValue}&STATUS=0&STAGE=1`;
  }
  public getKpgUrl(kpg: number | null): string {
    if (!kpg) {
      return '';
    }
    const encodekpgValue = encodeURIComponent(String(kpg));
    return `https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/coupon/index.cgi?KPG=${encodekpgValue}`;
  }


  public async getInactiveUsers() {
    const params = {}
    try {
      const response = await this.auth_service.fetchUsers(params).toPromise() as InactiveUsersResponse;
      const esimData = response.data.esims;
      console.log("Users fetched successfully:", esimData);
      this.data_source.data = esimData as ChargeListItem[];

      for (const esims of esimData) {
        const opdbValue = esims.id;
        console.log('esims.id:', opdbValue);
        if (opdbValue) {
          // const encodeOpdbValue = encodeURIComponent(opdbValue);
          const encodeOpdbValue = encodeURIComponent(String(opdbValue));
          const url_opdb = `${environment.operationDB}/operation/contract?contract_id=${encodeOpdbValue}`;
          console.log('OperationDB URL', url_opdb);
          this.urlOpdbMap[esims.id] = url_opdb;
        }
      }

      for (const data of esimData) {
        await this.iijInfoData(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }
  private async iijInfoData(data: any) {
    const params = {
      telno: data.telno,
      kpg: data.kpg,
      line_pause: data.line_pause,
      used_packets: data.used_packets
    };

    try {
      if (params.telno === null) {
        console.warn('telno is null, checking for other data.');

        if (params.line_pause !== undefined || params.used_packets !== undefined || params.kpg !== undefined) {
          console.log("Found additional data:");
          if (params.line_pause !== undefined) {
            console.log("line_pause:", params.line_pause);
          }
          if (params.used_packets !== undefined) {
            console.log("used_packets:", params.used_packets);
          }
          if (params.kpg !== undefined) {
            console.log("kpg:", params.kpg);
          }
        } else {
          console.log("No additional data found.");
          return;
        }
      } else {
        const responseData = await this.auth_service.iijInfo(params).toPromise() as {
          data: {
            telno: number | null,
            line_pause: number | null,
            used_packets: number | null,
            kpg: number | null
          }
        };
        // const telnoValue = responseData.data.telno
        // const telnoValue = String(responseData.data.telno);
        // const encodeTelnoValue = encodeURIComponent(telnoValue)
        // this.url_telno = `https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/index.cgi?TYPE=1&INFO=${encodeTelnoValue}&STATUS=0&STAGE=1`;
        this.url_telno = this.getTelnoUrl(responseData.data.telno);

        // const kpgValue = responseData.data.kpg;
        // const kpgValue = String(responseData.data.kpg);
        // const encodekpgValue = encodeURIComponent(kpgValue);
        // this.url_link = `https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/coupon/index.cgi?KPG=${encodekpgValue}`;
        this.url_link = this.getKpgUrl(responseData.data.kpg);

        console.log('responseData', responseData);
        console.log("Data data.line_pause:", responseData.data.line_pause);
        console.log("Data used_packets:", responseData.data.used_packets);
        console.log("Data used_packets:", responseData.data.kpg);
        console.log(this.data_source.data);
        console.log('url_telno', this.url_telno);
        console.log('url_link', this.url_link)
        this.data_source.data = this.data_source.data.map((item: any) =>
          item.telno === data.telno ? { ...item, ...responseData.data } : item
        );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  public async searchStartEndDate() {
    const params: any = {
      search_start_date: this.searchStartDate,
      search_end_date: this.searchEndDate,
      status: this.selectedStatus,
    };
    this.select_send_all = false;
    this.select_send_all_indeterminate = false;
    this.data_source.data.forEach(esims => (esims.is_selected = false));

    this.auth_service.searchDate(params).subscribe(
      async (data: InactiveUsersResponse) => {
        console.log('searchStartEndDate successfully:', data);
        console.log('esims>>:', data.data.esims);
        this.data_source.data = data.data.esims as ChargeListItem[];

        for (const esims of this.data_source.data) {
          const opdbValue = esims.id;

          if (opdbValue) {
            const encodeOpdbValue = encodeURIComponent(String(opdbValue));
            const url_opdb = `${environment.operationDB}/operation/contract?contract_id=${encodeOpdbValue}`;
            console.log('OperationDB URL', url_opdb);
            this.urlOpdbMap[esims.id] = url_opdb;
          }
        }

        for (const item of this.data_source.data) {
          await this.iijInfoSearchData(item);
        }
      },
      (error) => {
        console.error('searchStartEndDate Error:', error);
      }
    );
    this.selectedStatus = 'all';
  }

  private async iijInfoSearchData(data: any) {
    const params = {
      telno: data.telno,
      kpg: data.kpg,
      line_pause: data.line_pause,
      used_packets: data.used_packets
    };

    try {
      if (params.telno === null) {
        console.warn('telno is null, checking for other data.');

        if (params.line_pause !== undefined || params.used_packets !== undefined || params.kpg !== undefined) {
          console.log("Found additional data:");
          if (params.line_pause !== undefined) {
            console.log("line_pause:", params.line_pause);
          }
          if (params.used_packets !== undefined) {
            console.log("used_packets:", params.used_packets);
          }
          if (params.kpg !== undefined) {
            console.log("kpg:", params.kpg);
          }
        } else {
          console.log("No additional data found.");
          return;
        }
      } else {
        const responseData = await this.auth_service.iijInfo(params).toPromise() as {
          data: {
            telno: number | null,
            line_pause: number | null,
            used_packets: number | null,
            kpg: number | null
          }
        };
        // const telnoValue = String(responseData.data.telno);
        // const encodeTelnoValue = encodeURIComponent(telnoValue)
        // this.url_telno = `https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/index.cgi?TYPE=1&INFO=${encodeTelnoValue}&STATUS=0&STAGE=1`;
        this.url_telno = this.getTelnoUrl(responseData.data.telno);
        
        // const kpgValue = String(responseData.data.kpg);
        // const encodekpgValue = encodeURIComponent(kpgValue);
        // this.url_link = `https://help-gate.iij.ad.jp/service/kpd/kpd16486355/individual/coupon/index.cgi?KPG=${encodekpgValue}`;
        this.url_link = this.getKpgUrl(responseData.data.kpg);

        console.log('responseData', responseData);
        console.log("Data data.line_pause:", responseData.data.line_pause);
        console.log("Data used_packets:", responseData.data.used_packets);
        console.log("Data used_packets:", responseData.data.kpg);
        console.log('Kpg URL', this.url_link);
        console.log('Kpg URL', this.url_telno);
        console.log(this.data_source.data);
        this.data_source.data = this.data_source.data.map((item: any) =>
          item.telno === data.telno ? { ...item, ...responseData.data } : item
        );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  public getStatusLabel(status: string): string {
    if (status === '' || status === 'null') return '未設定';
    return status;
  }

  public async onFilterChange(event: Event, esims: ChargeListItem): Promise<void> {
    const selectElement = event.target as HTMLSelectElement;
    const newStatus = selectElement.value;
    console.log('newStatus', newStatus);
    const params = {
      status: newStatus,
      from_date: this.searchStartDate,
      to_date: this.searchEndDate,
      activation_status: this.statuses,
    };
    console.log('params:', params);
    this.auth_service.getActivationStatus(params).subscribe(
      async (data: InactiveUsersResponse) => {
        console.log('searchStartEndDate successfully:', data);
        console.log('searchStartEndDate successfully:', this.searchStartDate);
        console.log('esims>>:', data.data.esims);
        this.data_source.data = data.data.esims as ChargeListItem[];

        for (const item of this.data_source.data) {
          await this.iijInfoSearchData(item);
        }
      },
      (error) => {
        console.error('searchStartEndDate Error:', error);
      }
    );
  }


  public editNote(event: Event, esims: ChargeListItem) {
    esims.isEditing = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      if (this.noteInput) {
        this.noteInput.nativeElement.focus();
      }
    }, 0);
  }

  public calculateRows(note: string): number {
    if (note && note.length) {
      const lines = Math.ceil(note.length / 50);
      return Math.max(1, lines);
    }
    return 1;
  }

  public enterPressCount = 0;
  public handleEnterKey(event: KeyboardEvent, esims: ChargeListItem) {
    if (event.shiftKey) {
      return;
    }
    event.preventDefault();
    this.enterPressCount++;
    if (this.enterPressCount === 2) {
      this.saveNote(event, esims);
      this.enterPressCount = 0;
    }
  }

  public handleBlur(event: FocusEvent, esims: ChargeListItem) {
    if (esims.isEditing) {
      this.saveNote(event, esims);
    }
  }

  public saveNote(event: Event, esims: ChargeListItem) {
    console.log('editing note for:', esims);
    esims.isEditing = false;
    const selectElement = event.target as HTMLSelectElement;
    if (esims.note == null || /^\s*$/.test(esims.note)) {
      esims.note = '';
    }
    const params = {
      contract_product_id: esims.contract_product_id,
      activation_note: esims.note,
    };
    this.auth_service.editingNote(params).subscribe(
      (data) => {
        console.log('Users fetched successfully:', data);
      },
      (error) => {
        console.error('Error fetching users:', error);
        console.error('Error details:', error.error);
      }
    );
  }


  public onChangeStatus(event: Event, esims: ChargeListItem) {
    const selectElement = event.target as HTMLSelectElement;
    let newStatusText = selectElement.value;
    if (newStatusText === null) {
      newStatusText = '';
    }
    const newStatus = this.data.statuses[newStatusText] || this.data.statuses['未設定'];
    const params = {
      status: newStatusText,
      contract_product_id: esims.contract_product_id,
      activation_status: newStatus,
    };
    console.log('Params to be sent:', JSON.stringify(params));
    this.auth_service.activationStatus(params).subscribe(
      (data) => {
        console.log('Users fetched successfully:', data);
      },
      (error) => {
        console.error('Error fetching users:', error);
        console.error('Error details:', error.error);
      }
    );
  }


  public onStartContractChanged(searchStartDate: string): void {
    console.log('onStartContractChanged', searchStartDate)
    this.searchStartDate = searchStartDate;
    if (new Date(this.searchEndDate) < new Date(this.searchStartDate)) {
      this.search_end_error = true;
    } else {
      this.search_end_error = false;
    }
  }

  public onEndContractChanged(searchEndDate: string): void {
    console.log('onStartContractChanged', searchEndDate)
    this.searchEndDate = searchEndDate;
    if (new Date(this.searchStartDate) > new Date(this.searchEndDate)) {
      this.search_start_error = true;
    } else {
      this.search_start_error = false;
    }
  }


  public onSendAllCheckChanged(event: any): void {
    console.log('ListChargesComponent::onSendAllCheckChanged : checked=' + this.select_send_all);
    console.log('Current esims data before change:', this.data_source.data);
    this.data_source.data.forEach(esims => (esims.is_selected = this.select_send_all));
    this.select_send_all_indeterminate = false;
  }

  public onSendCheckChanged(event: any): void {
    console.log('ListChargesComponent::onSendCheckChanged : event=', this.select_send_all_disabled);
    console.log(event);
    let checked_count = 0;
    let unchecked_count = 0;
    this.data_source.data.forEach(esims => {
      if (esims.is_selected) {
        checked_count++;
      } else {
        unchecked_count++;
      }
    });

    this.select_send_all_disabled = true;
    if (checked_count <= 0 && unchecked_count <= 0) {
      this.select_send_all = false;
      this.select_send_all_indeterminate = true;
    } else if (checked_count > 0 && unchecked_count > 0) {
      this.select_send_all = true;
      this.select_send_all_indeterminate = true;
    } else if (checked_count <= 0 && unchecked_count > 0) {
      this.select_send_all = false;
      this.select_send_all_indeterminate = false;
    } else if (checked_count > 0 && unchecked_count <= 0) {
      this.select_send_all = true;
      this.select_send_all_indeterminate = false;
    } else {
      this.select_send_all = false;
      this.select_send_all_indeterminate = false;
    }
  }


  public onChangedDemandLevel(event: any): void {
    const filtered = this.demand_levels.filter(v => {
      return v.key === event.value;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.selecting_demand_level = filtered[0];
      console.log(this.selecting_demand_level);
    }
  }


  onClickSendDemand(event: any): void {
    const selectedUsers = this.data_source.data.filter(esims => esims.is_selected);
    if (selectedUsers.length === 0) {
      alert('※ メール送信するユーザーが選択されていません。');
      return;
    }

    if (!this.selecting_demand_level) {
      alert('※ 送信レベルが選択されていません。');
      console.log('送信レベルが選択されていません');
      return;
    }
    let selectedUserIds = this.data_source.data
      .filter(esims => esims.is_selected)
      .map(esims => esims.contract_product_id);
    const contractProductIds = selectedUserIds.length > 0
      ? selectedUserIds
      : this.data_source.data.map(esims => esims.contract_product_id);
    console.log('Contract Product IDs to send mail:', contractProductIds);

    const selectedLevel = Number(this.selecting_demand_level.key);

    const alreadySentUsers = selectedUsers.filter(esims =>
      esims.last_esim_email_type >= selectedLevel
    );
    if (alreadySentUsers.length > 0) {
      alert('※ 一度送信したレベルのメールが含まれています。');
      return;
    }
    const uniqueLevels = new Set(selectedUsers.map(esims => esims.last_esim_email_type));
    if (uniqueLevels.size > 1) {
      alert('※ 異なるレベルのユーザーが含まれています。');
      return;
    }

    const params = {
      contract_product_ids: contractProductIds,
      email_content_level: selectedLevel,
    };
    console.log('esim.id', this.data_source.data.map(esims => (esims.contract_product_id)))
    console.log('Current esims data:', this.data.esims);

    this.sendMails(params, () => {
      this.data_source.data.forEach(esims => {
        if (esims.is_selected) {
          esims.last_esim_email_type = selectedLevel;
        }
      });
      this.cdr.detectChanges();
    });
  }

  public sendMails(params: any, successCallback?: () => void): void {
    console.log('Attempting to send email with params:', params);
    this.auth_service.levelMail(params).subscribe(
      (data) => {
        if (successCallback) {
          successCallback();
        }
        console.log('email successfully:', data);
        alert('※ メールが送信されました。');
      },
      (error) => {
        console.error('send email Error:', error);
        // alert(error.message.message)
        alert('※ 問題なしのユーザー、送信してないlevelのメール、もしくは一度送信したlevelのメールが含まれている可能性があります。');
      }
    );
  }


  onMouseHover(index: number) {
    this.showUpdated[index] = true;
  }
  onMouseLeave(index: number) {
    this.showUpdated[index] = false;
  }

  onMouseHoverNote(index: number) {
    this.showUpdatedNote[index] = true;
  }
  onMouseHoverLeave(index: number) {
    this.showUpdatedNote[index] = false;
  }

  copyToClipboard(email: string, index: number): void {
    const textarea = document.createElement('textarea');
    textarea.value = email;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.showCopyMessage(index);
  }
  showCopyMessage(index: number): void {
    this.showMessage[index] = true;
    this.message = 'Copied!';

    setTimeout(() => {
      this.showMessage[index] = false;
      this.message = '';
    }, 1000);
  }

}
