<div class="app-list-charges">


  <table class="operation" style="width: 100%">
    <tr>

      <td style=" margin-top: 11px; ">
        <table class="activate_month">
          <tr>
            <td class="title">
              利用開始日 :&nbsp;
            </td>
            <td class="date">
              <mat-form-field class="date_pick">
                <input matInput type="date" [(ngModel)]="searchStartDate" [max]="maxStartDate"
                  (change)="onStartContractChanged($event.target.value)">
                <mat-hint>
                  <div *ngIf="search_start_error" class="search_start_error">Invalid</div>
                </mat-hint>
              </mat-form-field>

              <mat-form-field class="date_pick">
                <input matInput type="date" [(ngModel)]="searchEndDate" [max]="maxStartDate" [min]="searchStartDate"
                  (change)="onEndContractChanged($event.target.value)">
                <mat-hint>
                  <div *ngIf="search_end_error" class="search_end_error">Invalid</div>
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <button id="user_search_button" mat-flat-button class="warn" (click)="searchStartEndDate()">
          検索
        </button>
      </td>

      <td style="text-align: right;">
        <table style="margin-left:0;">
          <tr>
            <td>
              <mat-form-field style="min-width: 16rem; margin: 13px 0 0 0;">
                <mat-select placeholder="送信レベル" (selectionChange)="onChangedDemandLevel($event)">
                  <mat-option *ngFor="let item of demand_levels" [value]="item.key">
                    {{item.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <button id="demand_send_button" mat-flat-button class="warn"
                (click)="onClickSendDemand($event)">メール送信</button>
            </td>
          </tr>
        </table>
      </td>
      <td style="width: 300px; padding: 10px 0 0 38px; display: flex; align-items: center;"></td>
      <div style=" margin-top: 0; display: flex; padding-left: 50px;">
        <p style=" color: red; margin-top: 27px; font-size: 15px;">※今月使用量は前日分までが表示されます。</p>
        <p style=" color: red; margin-top: 27px; font-size: 15px;">※消費履歴リンクはリアルタイム表示です。</p>
      </div>
    </tr>
    <tr>

      <td class="activate-list-paginator" colspan="5">
        <mat-paginator class="charging-list-paginator" [pageSizeOptions]="[30, 60, 90, 120, 150, 180, 210]"
          [pageSize]="30" showFirstLastButtons></mat-paginator>
      </td>
    </tr>
  </table>
  <div class="list-invoices-table-container mat-elevation-z8">
    <table mat-table [dataSource]="data_source" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="is_selected">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [(ngModel)]="select_send_all" (change)="onSendAllCheckChanged($event)"
            (disabled)="select_send_all_disabled" [indeterminate]="select_send_all_indeterminate">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let esims">
          <mat-checkbox [(ngModel)]="esims.is_selected" (change)="onSendCheckChanged($event, esims);">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="status" style="display: block;">
        <th mat-header-cell *matHeaderCellDef>
          eSIM管理Status
          <select [(ngModel)]="selectedStatus" (change)="onFilterChange($event)">
            <option value="all">すべて</option>
            <option value="no_status">未設定</option>
            <option value="no_problem">問題なし</option>
            <option value="has_problem">問題あり</option>
          </select>
        </th>
        <td mat-cell *matCellDef="let esims; let i = index">
          <div style="position: relative;">
            <select name="status_select" [ngModel]="esims.status" (change)="onChangeStatus($event, esims)"
              (mouseenter)="onMouseHover(i)" (mouseleave)="onMouseLeave(i)">
              <option value="null" [selected]="'no_status' || esims.status === ''"></option>
              <option value="問題なし" [selected]="'no_problem' || esims.status === '問題なし'">問題なし</option>
              <option value="問題あり" [selected]="'has_problem' || esims.status === '問題あり'">問題あり</option>
            </select>
            <div class="tooltip-box" *ngIf="showUpdated[i] && esims.status_updated_by_email && esims.status_updated_at">
              {{ esims.status_updated_by_email }} - {{ esims.status_updated_at }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="contract_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ContractID </th>
        <td mat-cell *matCellDef="let esims">
          <a [href]="urlOpdbMap[esims.id]" target="_blank">{{ esims.contract_code }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ユーザー名</th>
        <td mat-cell *matCellDef="let esims">
          {{ esims.user_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> メールアドレス </th>
        <td mat-cell *matCellDef="let esims; let i = index" class="email-adress"
          (click)="copyToClipboard(esims.email, i)">
          {{ esims.email }}
          <div *ngIf="showMessage[i]" class="tooltip-box-copy">
            {{ message }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="start_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 利用開始日 </th>
        <td mat-cell *matCellDef="let esims" class="activate-start">
          {{ esims.start_at }}
        </td>
      </ng-container>

      <ng-container matColumnDef="telno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 回線番号 </th>
        <td mat-cell *matCellDef="let data" class="activate-telno">
          <a [href]="getTelnoUrl(data.telno)" target="_blank">{{data.telno}}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="line_pause">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 回線状態 </th>
        <td mat-cell *matCellDef="let data" class="activate-line-pause"> 
          {{ (data.line_pause === null || data.line_pause === undefined) ? '-' : (data.line_pause === false ? '正常' : '中断中') }} 
        </td>
      </ng-container>

      <ng-container matColumnDef="used_packets">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 今月使用量 </th>
        <td mat-cell *matCellDef="let data" class="activate-packets">{{data.used_packets || 0}}<span
            class="used-packets">MB</span></td>
      </ng-container>

      <ng-container matColumnDef="kpg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 消費履歴リンク </th>
        <td mat-cell *matCellDef="let data" class="activate-link">
          <a [href]="getKpgUrl(data.kpg)" target="_blank">LINK</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="demand_level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> メール送付日 </th>
        <td mat-cell *matCellDef="let esims" class="activate-email-at"> {{esims.last_sent_esim_email_at}} </td>
      </ng-container>

      <ng-container matColumnDef="mail_level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 最終Level </th>
        <td mat-cell *matCellDef="let esims" class="activate-level"> {{esims.last_esim_email_type}} </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> NOTE </th>
        <td mat-cell *matCellDef="let esims; let i = index" class="tooltip-box-index">
          <div tabindex="0" (mouseenter)="onMouseHoverNote(i)" (mouseleave)="onMouseHoverLeave(i)"
            *ngIf="!esims.isEditing" (click)="editNote($event, esims)" [class.empty-note]="!esims.note"
            class="note-content" [ngStyle]="{'white-space': 'pre-line'}">
            {{esims.note || 'Edit'}}
          </div>
          <textarea *ngIf="esims.isEditing" [(ngModel)]="esims.note" class="note-edit" #noteInput
            [rows]="calculateRows(esims.note)" (keydown.enter)="handleEnterKey($event, esims)"
            (blur)="saveNote($event, esims)" autofocus>
          </textarea>
          <!--note編集したユーザー-->
          <!-- <div *ngIf="showUpdatedNote[i] && esims.note_updated_by_email && esims.note_updated_at"
            class="tooltip-box-note" [ngClass]="{'hidden-note': esims.isEditing}">
            {{esims.note_updated_by_email}} - {{esims.note_updated_at}}
          </div> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="pay_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> 消費履歴リンク </th>
        <td mat-cell *matCellDef="let element"> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="display_columns;"></tr>
      <tr mat-row *matRowDef="let row; columns: display_columns;"></tr>
    </table>
  </div>
</div>