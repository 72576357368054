
export class EditUserPropertyDialogData {

}

export class EditContractPropertyDialogData {

}

export class EditProductPropertyDialogData {
}

export class ContractProductProperty {
  contract_product_id: number;
  contract_product_property_id: number;
  value: string;
  product_property_id: number;
  value_type: string;
  key: string;
  title: string;
  description: string;
  show_status: number;

  constructor() {
    this.contract_product_id = 0;
    this.contract_product_property_id = 0;
    this.value = '';
    this.product_property_id = 0;
    this.value_type = undefined;
    this.key = '';
    this.title = '';
    this.description = '';
    this.show_status = 0;
  }
}

export class ContractProduct {
  contract_id: number;
  contract_product_id: number;
  product_type_id: number;
  title: string;
  description: string;
  coupon_status: string;
  coupon_remain: number;
  bytes_in_month: number;
  v2_status_data: any;

  constructor() {
    this.contract_id = 0;
    this.contract_product_id = 0;
    this.product_type_id = 0;
    this.title = '';
    this.description = '';
    this.coupon_status = null;
    this.coupon_remain = null;
    this.bytes_in_month = null;
    this.v2_status_data = null;
  }
}
export class UserProperty {
  user_id: number;
  user_property_id: number;
  user_property_type_id: number;
  value_type: string;
  key: string;
  title: string;
  value: string;
  description: string;
  show_status: number;
  edited: boolean;  // 内部的な操作管理
  data: any;

  constructor() {
    this.user_id = 0;
    this.user_property_id = 0;
    this.user_property_type_id = 0;
    this.value_type = undefined;
    this.key = '';
    this.title = '';
    this.value = '';
    this.description = '';
    this.show_status = 0;
    this.edited = false;
    this.data = undefined;
  }
}
export class UpdateUser {
  id: number;
  user_e_mail_address: string;
  user_full_name: string;

  constructor() {
    this.id = 0;
    this.user_e_mail_address = '';
    this.user_full_name = '';
  }
}
export class UserSummary {
  id: number;
  email: string;
  sign_in_count: number;
  confirmed_at: string;
  confirmation_sent_at: string;
  created_at: string;
  updated_at: string;
  edited: boolean;  // 内部的な操作管理

  constructor() {
    this.id = 0;
    this.email = '';
    this.sign_in_count = 0;
    this.confirmed_at = '';
    this.confirmation_sent_at = '';
    this.created_at = '';
    this.updated_at = '';
    this.edited = false;
  }
}
export class ContractProperty {
  contract_id: number;
  contract_property_id: number;
  contract_property_type_id: number;
  value_type: string;
  key: string;
  title: string;
  description: string;
  value: string;
  show_status: boolean;
  last_updated_by: UpdateUser; // 最終更新者
  last_updated_at: string;
  edited: boolean;  // 内部的な操作管理
  option: any;  // 内部的な制御

  constructor() {
    this.contract_id = 0;
    this.contract_property_id = 0;
    this.contract_property_type_id = 0;
    this.value_type = undefined;
    this.key = '';
    this.title = '';
    this.description = '';
    this.value = '';
    this.show_status = false;
    this.last_updated_by = undefined;
    this.last_updated_at = undefined;
    this.edited = false;
  }
}
export class ContractSummary {
  id: number;
  user_id: number;
  contract_code: string;
  contract_hash: string;
  status: number;
  start_at: string;
  end_at: string;
  created_at: string;
  updated_at: string;
  edited: boolean;  // 内部的な操作管理

  constructor() {
    this.id = 0;
    this.user_id = 0;
    this.contract_code = '';
    this.contract_hash = '';
    this.status = undefined;
    this.start_at = undefined;
    this.end_at = undefined;
    this.created_at = undefined;
    this.updated_at = undefined;
    this.edited = false;
  }
}

export class ContractContent {
  contract_summary: ContractSummary;
  contract_properties: Array<ContractProperty>;
  user_summary: UserSummary;
  user_properties: Array<UserProperty>;
  product_summaries: Array<ContractProduct>;
  product_properties: Array<ContractProductProperty>;

  constructor() {
    this.contract_summary = new ContractSummary();
    this.contract_properties = new Array<ContractProperty>();
    this.user_summary = new UserSummary();
    this.user_properties = new Array<UserProperty>();
    this.product_summaries = new Array<ContractProduct>();
    this.product_properties = new Array<ContractProductProperty>();
  }
}
