import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ContractListItem,
  AuthGetReply,
  ContractStatus
} from '../../../models';
import { Utils } from '../../../helpers';
import { Subscription } from 'rxjs';
import { ListFiberSchedulesService } from '../../../services/list';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import { Candidates } from 'src/app/constants';
import { Url } from '../../../constants/url';
import * as Encoding from 'encoding-japanese';
import { L } from '@angular/core/src/render3';

@Component({
  selector: 'app-list-fiber-schedules',
  templateUrl: './list-fiber-schedules.component.html',
  styleUrls: ['./list-fiber-schedules.component.scss']
})
export class ListFiberSchedulesComponent implements OnInit, OnDestroy {
  public fiber_schedule_status_filter_form: FormControl;
  public fiber_schedule_status_titles: Array<string>;
  public fiber_schedule_status_filters: Array<string>;

  public fiber_schedule_date_filter_form: FormControl;
  public fiber_schedule_date_filter_types: Array<string>;
  public fiber_schedule_date_filter_selected: string;
  public fiber_schedule_date_from: Date;
  public fiber_schedule_date_to: Date;

  public listItems: Array<any>;
  public displayItems: Array<any>;

  public uploadYamatoCSVFile: File;

  public data_source: MatTableDataSource<any>;
  public selection = new SelectionModel<any>(true, []);
  public display_columns: Array<string>;

  protected _loading_animation;
  protected _el: HTMLElement;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    private list_fiber_schedules_service: ListFiberSchedulesService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) { 
    this.fiber_schedule_status_filter_form = new FormControl();
    this.fiber_schedule_status_filters = [];
    this.fiber_schedule_status_titles = ['ステータスなし'];
    Candidates.fiber_statuses.forEach(status => {
      this.fiber_schedule_status_titles.push(status);
    });
    this._el = el.nativeElement;
    this.display_columns = [
      'select',
      'fiber_schedule_status',
      'contract_code',
      'email',
      'user_full_name',
      'ntt_region',
      'router_model', 
      'preliminary_investigation_dt_fixed',
      'install_dt_fixed',
      'support_date_at',
      'isp_registoration',
      'router_arrengement_data_exported',
      'router_tracking_number',
      'shipment_notified',
      'inst_reminder_notified',
      'connection_confirmed',
      'inst_fee_pay_status'
    ];
    this.fiber_schedule_date_filter_types = ['---', '事前調査日', '工事日', '対応予定日'];
    this.fiber_schedule_date_filter_selected = this.fiber_schedule_date_filter_types[0];
    this.fiber_schedule_date_from = new Date;
    this.fiber_schedule_date_to = new Date;
  }

  @ViewChild(MatSort)
  sort: MatSort;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  ngOnInit() {
    this.refresh(true);
  }

  ngOnDestroy() {
  }

  public onStatusFilterSelectionChanged(event: any): void {
    // 使用可能なステータスの中から該当を検索
    const statuses = this.fiber_schedule_status_titles.filter(v => {
      return v === event.source.value;
    });
    if (statuses.length > 0) {
      // フィルタの配列からとりあえず削除
      this.fiber_schedule_status_filters = this.fiber_schedule_status_filters.filter(v => {
        console.log(statuses[0]);
        return v !== statuses[0];
      });
      // フィルタ配列に追加
      if (event.source.selected === true) {
        this.fiber_schedule_status_filters.push(statuses[0]);
      }
    }
    console.log(this.fiber_schedule_status_filters);
    this.refresh(false);  // 表示内容を更新
  }

  public onDateFilterTypeChanged(event: any): void {
    this.fiber_schedule_date_filter_selected = event.srcElement.value;
    console.log("onDateFilterTypeChanged from: ",this.fiber_schedule_date_from);
    console.log("onDateFilterTypeChanged to: ",this.fiber_schedule_date_to);
    this.refresh(false);  // 表示内容を更新
  }

  public onDateFilterFromChanged(event: any): void {
    const new_date = Utils.dateToDateString(event.value);
    if (!new_date) {
      event.targetElement.classList.add( "error" )
      this.snackBarError("Invalid date", "OK");
      return;
    }
    this.fiber_schedule_date_from = new Date(new_date);
    console.log("onDateFilterFromChanged : ",this.fiber_schedule_date_from);
    this.refresh(false);  // 表示内容を更新
  }

  public onDateFilterToChanged(event: any): void {
    const new_date = Utils.dateToDateString(event.value);
    if (!new_date) {
      event.targetElement.classList.add( "error" )
      this.snackBarError("Invalid date", "OK");
      return;
    }
    this.fiber_schedule_date_to = new Date(`${new_date} 23:59:59`);
    console.log("onDateFilterToChanged : ",this.fiber_schedule_date_to);
    this.refresh(false);  // 表示内容を更新
  }

  private isDateMatch(item: any): boolean {
    if (this.fiber_schedule_date_filter_selected === '事前調査日') {
      if (!item.preliminary_investigation_dt_fixed) {
        return false;
      }
      else {
        const item_dt = JSON.parse(item.preliminary_investigation_dt_fixed);
        if (!item_dt.date) {
          return false;
        }
        const parsedDate = new Date(item_dt.date);
        if ((!this.fiber_schedule_date_from || this.fiber_schedule_date_from <= parsedDate) && 
            (!this.fiber_schedule_date_to || this.fiber_schedule_date_to >= parsedDate)) {
              return true;
        }
        return false;
      }
    }
    else if (this.fiber_schedule_date_filter_selected === '工事日') {
      if (!item.install_dt_fixed) {
        return false;
      }
      else {
        const item_dt = JSON.parse(item.install_dt_fixed);
        if (!item_dt.date) {
          return false;
        }
        const parsedDate = new Date(item_dt.date);
        if ((!this.fiber_schedule_date_from || this.fiber_schedule_date_from <= parsedDate) && 
            (!this.fiber_schedule_date_to || this.fiber_schedule_date_to >= parsedDate)) {
              return true;
        }
        return false;
      }
    }
    else if (this.fiber_schedule_date_filter_selected === '対応予定日') {
      if (!item.support_date_at) {
        return false;
      }
      else {
        const parsedDate = new Date(item.support_date_at);
        if ((!this.fiber_schedule_date_from || this.fiber_schedule_date_from <= parsedDate) && 
            (!this.fiber_schedule_date_to || this.fiber_schedule_date_to >= parsedDate)) {
              return true;
        }
        return false;
      }
    }
    return true;
  }

  private filterDisplayItems() {
    if (Utils.isValue(this.listItems)) {
      this.displayItems = [];
      if (this.fiber_schedule_status_filters.length > 0) {
        this.displayItems = this.listItems.filter(item => {
          const  date_match = this.isDateMatch(item);
          console.log(`日時 :`, {
            "対応予定日 : support_date_at" : item.support_date_at,
            "工事日 : install_dt_fixed" : JSON.parse(item.install_dt_fixed),
            "事前調査日 : preliminary_investigation_dt_fixed" : JSON.parse(item.install_dt_fixed)
          });
          if (date_match) {
            if (!item.fiber_schedule_status) {
              return this.fiber_schedule_status_filters.includes('ステータスなし');
            }
            else {
              return this.fiber_schedule_status_filters.includes(item.fiber_schedule_status);
            }
          }
        });
      } 
      else {
        this.displayItems = this.listItems.filter(item => {
          if (this.isDateMatch(item)) {
            return true;
          }
          return false;
        });
      }
      this.data_source = new MatTableDataSource(this.displayItems);
      this.data_source.sort = this.sort;
      this.data_source.paginator = this.paginator;
    }
  }

  public refresh(from_db: boolean) {
    this.selection.clear();
    if (from_db) {
      const params = {};

      if (!Utils.isValue(this._loading_animation)) {
        this._loading_animation = this._el.querySelector('#contract_list_updating');
      }
      this._loading_animation.style.opacity = 1.0;  // show loading icon;

      this.auth_service.apiGetFiberSchedules().subscribe(
        response => {
          this._loading_animation.style.opacity = 0.0;
          // console.log('ListFiberSchedulesComponent : apiGetFiberSchedules(success)', response);
          this.listItems = response.data;
          this.filterDisplayItems();
        },
        error => {
          this._loading_animation.style.opacity = 0.0;
          console.log('ListFiberSchedulesComponent : apiGetFiberSchedules(error)', error);
        }
      );
    }
    else {
      this.filterDisplayItems();
    }
  }

  public datetimeStringToDisplayDate(dt: string): string {
    // console.log(dt);
    if (!Utils.isValue(dt)) {
      return "";
    }
    return Utils.getDatePart(dt, false);
  }

  public dateAndTimeToDate(dt: string): string {
    if (!Utils.isValue(dt)) {
      return "";
    }
    const data = JSON.parse(dt);
    return data.date;
  }

  public dateAndTimeToTime(dt: string): string {
    if (!Utils.isValue(dt)) {
      return "";
    }
    const data = JSON.parse(dt);
    return data.time;
  }

  public displayBool(value: boolean, t: string, f: string): string {
    if (!value) {
      return f;
    }
    return t;
  }

  public onRefreshList(event: any): void {
    this.refresh(true);
  }

  public onClearStatusFilter(event: any): void {
    this.fiber_schedule_status_filters = [];
    console.log(`ListFiberSchedulesComponent::onClearStatusFilter : `, event);
  }

  public onClickUploadYamatoCSV(event: any): void {
    console.log(`ListFiberSchedulesComponent::onClickUploadYamatoCSV : `, event);
    this.uploadYamatoCSVFile = event.target.files[0];
    if (!this.uploadYamatoCSVFile) {
      return;
    }
    
    // continue to uploading
    console.log('onClickUploadYamatoCSV() - ' + this.uploadYamatoCSVFile.name);
    this.auth_service.upload(Url.POST_FIBER_IMPORT_YAMATO_CSV, this.uploadYamatoCSVFile).
    subscribe(
      response => {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 2000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open(
          'ヤマトCSVの取り込み完了しました',
          'OK',
          cfg);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        if (error.error.message && error.error.message.message) {
          this.snackBar.open(
            'ヤマトCSVの取り込みに失敗[' + error.error.message.message + ']',
            'OK',
            cfg);
        } else if (error.message) {
          this.snackBar.open(
            'ヤマトCSVの取り込みに失敗[' + error.message + ']',
            'OK',
            cfg);
        }
      }
    );
    this.snackBar.dismiss();
    const cfg_info = new MatSnackBarConfig();
    cfg_info.duration = 2000;
    cfg_info.panelClass = ['notify_snackbar', 'information'];
    this.snackBar.open(
      'File import takes long time. Please ignore if timeout error had occured.',
      'OK',
      cfg_info);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data_source.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data_source.data.forEach(row => this.selection.select(row));
  }

  public onClickRemindNotification(event: any): void {
    console.log(`ListFiberSchedulesComponent::onClickRemindNotification : `, event);

    const target_ids = [];

    this.selection.selected.forEach(selected => {
      target_ids.push(selected.contract_product_id);
    });
    this.auth_service.apiPostInstRemindNotification({
      contract_product_ids: target_ids
    }).subscribe(
      response =>  {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 3000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open("事前連絡を送信しました。", 'OK', cfg);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }
  
  public onClickShippingNotification(event: any): void {
    console.log(`ListFiberSchedulesComponent::onClickLineOpenNotification : `, event);

    const target_ids = [];
    this.selection.selected.forEach(selected => {
      target_ids.push(selected.contract_product_id);
    });
    this.auth_service.apiPostShippingdNotification({
      contract_product_ids: target_ids
    }).subscribe(
      response =>  {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 3000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open("発送連絡を送信しました", 'OK', cfg);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }

  public onClickLineOpenNotification(event: any): void {
    console.log(`ListFiberSchedulesComponent::onClickLineOpenNotification : `, event);

    const target_ids = [];
    this.selection.selected.forEach(selected => {
      target_ids.push(selected.contract_product_id);
    });
    this.auth_service.apiPostLineOpenNotification({
      contract_product_ids: target_ids
    }).subscribe(
      response =>  {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 3000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open("開通通知を送信しました", 'OK', cfg);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }

  private generateYamatoCSVHeader(): string {
    const header = "伝票番号,伝票・サイズコード,お届け希望日,お届け希望時間区分,お届け先電話番号,お届け先郵便番号,お届け先住所１,お届け先住所２,お届け先住所３,お届け先名,お届け先敬称,依頼主電話番号,依頼主郵便番号,依頼主住所１,依頼主住所２,依頼主住所３,依頼主名,記事,品名１,品名２,注文日,商品コード（ハウスコード）,商品名称,商品数量,商品税込単価,商品金額,総合計金額,総合計消費税（※税込・税抜）,商品合計金額,送料,手数料・その他,ポイント利用,値引き,納品書金額表示フラグ,ラッピングフラグ（明細単位）,明細備考02,明細備考03,明細備考04,明細備考05,ラッピングフラグ（注文単位）,ストア名称,ストア郵便番号,ストア電話番号,ストア住所,注文ID,顧客（販売）商品コード,サイト名,納品書レスフラグ,同梱品フラグ,10％金額合計,8％金額合計,荷届先メールアドレス,配達場所指定コード,モール区分";
    return header + "\r\n";
  }

  private generateYamatoCSVRecord(src: any): string {
    console.log("generateYamatoCSVRecord", src);
    console.log(`Fiber-line-number : ${src.line_number}`);
    const recAry = [];

    // initialize (fields = 53)
    for (let i = 0; i < 53; i++) {
      recAry.push("");
    }

    // 伝票番号
    recAry[0] = src.contract_code;
    // 伝票・サイズコード
    recAry[1] = "0101";
    // お届け希望日
    recAry[2] = src.delivery_date;
    // お届け希望時間区分
    recAry[3] = src.delivery_time;
    // お届け先電話番号
    recAry[4] = src.line_number;
    // お届け先郵便番号
    recAry[5] = src.post_code;
    // お届け先住所１
    recAry[6] = src.address_1;
    // お届け先住所２
    recAry[7] = src.address_2;
    // お届け先住所３
    recAry[8] = src.address_3;
    // お届け先名
    recAry[9] = src.customer_name;
    // お届け先敬称
    recAry[10] = "様";
    // 依頼主電話番号
    recAry[11] = "0359375868";
    // 依頼主郵便番号
    recAry[12] = "1600023";
    // 依頼主住所１
    recAry[13] = "東京都";
    // 依頼主住所２
    recAry[14] = "新宿区西新宿1-3-13";
    // 依頼主住所３
    recAry[15] = "Zenken Plaza II 6F";
    // 依頼主名
    recAry[16] = "Sakura Fiber Internet";
    // 記事
    // 品名１
    recAry[18] = "Wireless Router";
    // 品名２
    // 注文日
    recAry[20] = src.order_date
    // 商品コード（ハウスコード）
    recAry[21] = src.router_model;
    // 商品名称
    recAry[22] = "Wireless Router";
    // 商品数量
    recAry[23] = "1";
    // 商品税込単価
    recAry[24] = "0";
    // 商品金額
    recAry[25] = "0";
    // 総合計金額
    recAry[26] = "0";
    // 総合計消費税（※税込・税抜）
    recAry[27] = "0";
    // 商品合計金額
    recAry[28] = "0";
    // 送料
    recAry[29] = "0";
    // 手数料・その他
    recAry[30] = "0";
    // ポイント利用
    recAry[31] = "0";
    // 値引き
    recAry[32] = "0";
    // 納品書金額表示フラグ
    recAry[33] = "1";
    // ラッピングフラグ（明細単位）
    // 明細備考02
    // 明細備考03
    // 明細備考04
    // 明細備考05
    // ラッピングフラグ（注文単位）
    // ストア名称
    recAry[40] = "Sakura Fiber Internet";
    // ストア郵便番号
    recAry[41] = "160023";
    // ストア電話番号
    recAry[42] = "0359375868";
    // ストア住所
    recAry[43] = "東京都新宿区西新宿1-3-13 Zenken Plaza II 6F";
    // 注文ID
    recAry[44] = src.contract_code;
    // 顧客（販売）商品コード
    recAry[45] = src.router_model;
    // サイト名
    recAry[46] = "Sakura Fiber Internet";
    // 納品書レスフラグ
    // 同梱品フラグ
    // 10％金額合計
    recAry[49] = "0";
    // 8％金額合計
    recAry[50] = "0";
    // 荷届先メールアドレス
    // 配達場所指定コード
    // モール区分
    recAry[53] = "4";
    return recAry.join(',') + "\r\n";
  }

  public onExportYamatoCSV(event: any): void {
    console.log("onExportYamatoCSV", this.selection.selected);
    console.log("onExportYamatoCSV",Utils.getDisplayDateString((new Date()).toISOString()));

    let unc_records = "";
    unc_records += this.generateYamatoCSVHeader();
    let error = false;
    const exported_ids = [];
    console.log(this.selection.selected);
    this.selection.selected.forEach(selected => {
      let delivery_date = "";
      let delivery_time = "";
      
      const delivery_dt = JSON.parse(selected.device_delivery_dt_fixed);
      if (!delivery_dt || !delivery_dt.date) {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(`配送日の指定がありません ${selected.contract_code}`, 'OK', cfg);
        error = true;
        return;
      }
      if (delivery_dt && delivery_dt.date) {
        delivery_date = delivery_dt.date.split('-').join('');
      }
      if (delivery_dt && delivery_dt.time) {
        if (delivery_dt.time && delivery_dt.time !== '---') {
          if (delivery_dt.time === '8-12') {
            delivery_time = '08';
          }
          else if (delivery_dt.time === '14-16') {
            delivery_time = '14';
          }
          else if (delivery_dt.time === '16-18') {
            delivery_time = '16';
          }
          else if (delivery_dt.time === '18-20') {
            delivery_time = '18';
          }
          else if (delivery_dt.time === '19-21') {
            delivery_time = '19';
          }
        }
      }
      if (!selected.ntt_system_inst_address) {
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(`配送先住所の記載がありません ${selected.contract_code}`, 'OK', cfg);
        error = true;
        return;
      }
      const ntt_system_inst_address = selected.ntt_system_inst_address.split('\n');
      for (let i = 0; i < 4; i++) {
        ntt_system_inst_address.push("");
      }
      let line_number = selected.fiber_phone_number;
      if (!line_number) {
        line_number = "0359375946";
      }
      else {
        line_number = line_number.split('-').join('')
      }
      unc_records += this.generateYamatoCSVRecord({
        contract_code: selected.contract_code,
        customer_name: selected.user_full_name.substr(0, 32).trim(),
        line_number: line_number,
        post_code: ntt_system_inst_address[0].match(/[0-9]/g).join(''),
        address_1: ntt_system_inst_address[1],
        address_2: ntt_system_inst_address[2],
        address_3: ntt_system_inst_address[3],
        order_date: Utils.getDisplayDateString((new Date()).toISOString()).split('-').join(''),
        router_model: selected.router_model,
        delivery_date: delivery_date,
        delivery_time: delivery_time,
      });
      exported_ids.push(selected.contract_product_id);
    });

    if (error) {
      return;
    }

    const unc_data = Encoding.stringToCode(unc_records);
    const sjis_data = Encoding.convert(unc_data, 'SJIS', 'UNICODE');
    const u8a = new Uint8Array(sjis_data);
    const file = new Blob([u8a], {type: 'text/csv'});
    const download_el = document.createElement('a');
    download_el.download = `yamato_fiber.csv`
    download_el.href = window.URL.createObjectURL(file);
    download_el.click();

    this.auth_service.apiPostFiberYamatoCSVExport({
      ids: exported_ids
    }).subscribe(
      response =>  {
        console.log(response);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 3000;
        cfg.panelClass = ['notify_snackbar', 'success'];
        this.snackBar.open("指定された契約を配送手配済みに変更しました。", 'OK', cfg);
      },
      error => {
        console.log(error);
        this.snackBar.dismiss();
        const cfg = new MatSnackBarConfig();
        cfg.duration = 5000;
        cfg.panelClass = ['notify_snackbar', 'error'];
        this.snackBar.open(error.error.message.message, 'OK', cfg);
      }
    );
  }

  private snackBarSuccess(message: string, button: string): void {
    console.log(`snackBarSuccess ${message}, ${button}`);
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 3000;
    cfg.panelClass = ['notify_snackbar', 'success'];
    this.snackBar.open(message, button, cfg);
  }

  private snackBarError(message: string, button: string): void {
    console.log(`snackBarError ${message}, ${button}`);
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 5000;
    cfg.panelClass = ['notify_snackbar', 'error'];
    this.snackBar.open(message, button, cfg);
  }
}
