<div class="fiber-schedule">
  <table>
    <tr>
      <td>
        <table>
          <tr>
            <td class="section-title">[申し込み情報]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">Status</td>
            <td>
              <select
                name="fiber_schedule_status_selected"
                (change)="
                  setFiberScheduleSelectOption('fiber_schedule_status', $event)
                "
              >
                <option
                  *ngFor="let candidate of fiberScheduleStatusCandidates"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('fiber_schedule_status')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">書類アップロードリンク</td>
            <td class="TBD">
              <a [href]="getDocumentUploadLink()" target="_blank">Link</a>
            </td>
          </tr>
          <tr>
            <td class="item-title right">管理会社確認フォームリンク</td>
            <td class="TBD">
              <div
                *ngIf="
                  getFiberScheduleString('fiber_schedule_status') ===
                  '管理会社フォーム返答待ち'
                "
              >
                <a [href]="getScheduleOptionFormLink()" target="_blank">Link</a>
              </div>
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td class="item-title right">スケジュール調整フォームリンク</td>
            <td class="TBD">
              <div
                *ngIf="
                  getFiberScheduleString('fiber_schedule_status') === '工事日程調整中'
                "
              >
                <a [href]="getScheduleInputFormLink()" target="_blank">Link</a>
              </div>
            </td>
          </tr>
          <tr>
            <td class="item-title right">確定スケジュールリンク</td>
            <td class="TBD">
              <button (click)="onUpdateFixedSchedule()">更新</button>
              <a [href]="fixedScheduleLink" target="_blank">Link</a>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">契約コード</td>
            <td>
              <a
                *ngIf="data && data.contract"
                class="contract_code"
                target="_blank"
                href="{{
                  '/operation/contract?contract_id=' + data.contract.id
                }}"
                >{{ data.contract.contract_code }}</a
              >
            </td>
          </tr>
          <tr>
            <td class="item-title right">ユーザー名</td>
            <td>
              {{ getUserPropertyString("USER FULL NAME") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">メールアドレス(契約時)</td>
            <td>
              {{ getUserPropertyString("USR EMAIL ADDRESS") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">電話番号</td>
            <td>
              {{ getUserPropertyString("TEL") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">支払い方法</td>
            <td>
              {{ getContractPropertyString("PAY METHOD") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">GMOへの登録</td>
            <td>
              <ng-container *ngIf="this.hasGMO() === false; else has_gmo">
                登録なし: <button class="btn btn-allowed" (click)="postGMORegistrateMemberID()">会員登録する</button>
                <br/>
                {{ this.getUserPropertyString("GMO USER ID") }}
              </ng-container>
              <ng-template #has_gmo>
                登録あり:
                <br/>
                {{ this.getUserPropertyString("GMO USER ID") }}
              </ng-template>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              I have other contracts with Sakura Mobile
            </td>
            <td>
              {{
                getContractProductPropertyBool(
                  "FIBER HAS OTHER CONTRACT",
                  "YES",
                  "NO"
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">I am a minor (under 18 years old)</td>
            <td>
              {{
                getContractProductPropertyBool(
                  "FIBER HOLDER IS MINOR",
                  "YES",
                  "NO"
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              I have my parent's approval to make this contract
            </td>
            <td>
              {{
                getContractProductPropertyBool(
                  "FIBER GUARDIANS APPROVAL",
                  "YES",
                  "NO"
                )
              }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              I have my parent's approval to make this contract
            </td>
            <td>
              {{
                getContractProductPropertyString("FIBER LIVING OR MOVE", true)
              }}
            </td>
          </tr>
          <tr *ngFor="let file_name of getDocuments()">
            <td class="item-title right">書類</td>
            <td>
              <a href="{{ apiRoot }}/{{ file_name }}" target="_blank">{{
                file_name
              }}</a>
            </td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="section-title">[Address(from Application form)]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">Postcode/ZIP</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER LOCATION POST CODE')
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER LOCATION POST CODE',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Block in Japanese</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER LOCATION BLOCK IN JP')
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER LOCATION BLOCK IN JP',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Block in English</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER LOCATION BLOCK IN EN')
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER LOCATION BLOCK IN EN',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Street number</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER LOCATION STREET')
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER LOCATION STREET',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Building name / Room number</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString(
                    'FIBER LOCATION BUILDING ROOM'
                  )
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER LOCATION BUILDING ROOM',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Google map info for install location
            </td>
            <td>
              <a
                href="https://www.google.co.jp/maps?ll={{
                  getFiberLocation('lat')
                }},{{ getFiberLocation('lng') }}&q={{
                  getFiberLocation('lat')
                }},{{ getFiberLocation('lng') }}&z=20z"
                target="_blank"
              >
                Google Map
              </a>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Installation Address Type</td>
            <td>
              <select
                name="fiber_location_address_type_selected"
                [value]="
                  getContractProductPropertyString(
                    'FIBER LOCATION ADDRESS TYPE'
                  )
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER LOCATION ADDRESS TYPE',
                    $event
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['Home', 'Office']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Building Type</td>
            <td>
              <select
                name="fiber_location_address_type_selected"
                [value]="
                  getContractProductPropertyString(
                    'FIBER LOCATION BUILDING TYPE'
                  )
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER LOCATION BUILDING TYPE',
                    $event
                  )
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      'Apartment Type (Rented)',
                      'Apartment Type (Owned)',
                      'House Type (Rented)',
                      'House Type (Owned)',
                      'Dormitory',
                      'Other'
                    ]
                  "
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Message</td>
            <td>
              <textarea
                [value]="
                  getContractProductPropertyString('FIBER INQUIRE MESSAGE')
                "
                (change)="
                  setContractProductPropertyString(
                    'FIBER INQUIRE MESSAGE',
                    $event
                  )
                "
              >
              </textarea>
            </td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="section-tittle">Desired installation date/time</td>
            <td></td>
          </tr>
          <tr *ngVar="getDesiredInstallationDateTime('first'); let item">
            <td class="item-title right">
              {{ item.order }}
            </td>
            <td>
              <input
                matInput
                [matDatepicker]="desiredInstallationDateTimeDateFirstPicker"
                placeholder="none"
                [value]="item.dt.date"
                (dateChange)="
                  setDesiredInstallationDateTimeDate(item.order, $event)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="desiredInstallationDateTimeDateFirstPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #desiredInstallationDateTimeDateFirstPicker
              ></mat-datepicker>
              <select
                name="desiredInstallationDateTimeFirstTimeSelected"
                [value]="item.dt.time"
                (change)="
                  setDesiredInstallationDateTimeTime(item.order, $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '9:00 - 12:00', '13:00 - 17:00']
                  "
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr *ngVar="getDesiredInstallationDateTime('second'); let item">
            <td class="item-title right">
              {{ item.order }}
            </td>
            <td>
              <input
                matInput
                [matDatepicker]="desiredInstallationDateTimeDateSecondPicker"
                placeholder="none"
                [value]="item.dt.date"
                (dateChange)="
                  setDesiredInstallationDateTimeDate(item.order, $event)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="desiredInstallationDateTimeDateSecondPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #desiredInstallationDateTimeDateSecondPicker
              ></mat-datepicker>
              <select
                name="desiredInstallationDateTimeSecondTimeSelected"
                [value]="item.dt.time"
                (change)="
                  setDesiredInstallationDateTimeTime(item.order, $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '9:00 - 12:00', '13:00 - 17:00']
                  "
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr *ngVar="getDesiredInstallationDateTime('third'); let item">
            <td class="item-title right">
              {{ item.order }}
            </td>
            <td>
              <input
                matInput
                [matDatepicker]="desiredInstallationDateTimeDateThirdPicker"
                placeholder="none"
                [value]="item.dt.date ? item.dt.date : null"
                (dateChange)="
                  setDesiredInstallationDateTimeDate(item.order, $event)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="desiredInstallationDateTimeDateThirdPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #desiredInstallationDateTimeDateThirdPicker
              ></mat-datepicker>
              <select
                name="desiredInstallationDateTimeThirdTimeSelected"
                [value]="item.dt.time"
                (change)="
                  setDesiredInstallationDateTimeTime(item.order, $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '9:00 - 12:00', '13:00 - 17:00']
                  "
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="section-tittle">A convenient day of the week</td>
            <td></td>
          </tr>
          <tr *ngFor="let item of getConvinientDayOfTheWeek()">
            <td class="item-title right">
              {{ item.order }}
            </td>
            <td>
              <select
                name="aConvinientDayOfTheWeek"
                [value]="item.value"
                (change)="setAConvinientDayOfTheWeek(item.order, $event)"
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '9:00 - 12:00',
                      '13:00 - 17:00',
                      '9:00 - 17:00',
                      'Not available'
                    ]
                  "
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>

          <!-- ### SECTION ### -->
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">
              Are you currently using a fixed-line internet service?
            </td>
            <td>
              <select
                name="aConvinientDayOfTheWeek"
                [value]="
                  getContractProductPropertyString(
                    'FIBER CURRENT USING FIBER',
                    false
                  )
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CURRENT USING FIBER',
                    $event
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', 'no', 'yes', 'unknown']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Internet connection type</td>

            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString(
                    'FIBER CURRENT CONNECTION TYPE'
                  )
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CURRENT CONNECTION TYPE',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Current Contract Service name</td>

            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER CURRENT SERVICE PLAN')
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CURRENT SERVICE PLAN',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Current Internet Service Provider (ISP)
            </td>

            <td>
              <input
                class="full-width"
                type="text"
                [value]="getContractProductPropertyString('FIBER CURRENT ISP')"
                (change)="
                  updateContractProductProperty('FIBER CURRENT ISP', $event)
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Why are you changing services?</td>

            <td>
              <textarea
                [value]="
                  getContractProductPropertyString(
                    'FIBER CURRENT CHANGE REASON'
                  )
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CURRENT CHANGE REASON',
                    $event
                  )
                "
              >
              </textarea>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Note</td>
            <td>
              <textarea
                [value]="getContractProductPropertyString('FIBER CURRENT NOTE')"
                (change)="
                  updateContractProductProperty('FIBER CURRENT NOTE', $event)
                "
              >
              </textarea>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Do you have a fiber-optic (光-Hikari) port in your residence
              already?
            </td>
            <td>
              <select
                name="aConvinientDayOfTheWeek"
                [value]="
                  getContractProductPropertyString(
                    'FIBER HAS OPTIC PORT',
                    false
                  )
                "
                (change)="
                  updateContractProductProperty('FIBER HAS OPTIC PORT', $event)
                "
              >
                <option
                  *ngFor="let candidate of ['---', 'no', 'yes', 'unknown']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              I would like to rent a Pocket WIFI device until the installation
              date
            </td>

            <td>
              <select
                name="aConvinientDayOfTheWeek"
                [value]="
                  getContractProductPropertyBool(
                    'FIBER WIFI ROUTER RENTAL',
                    'YES',
                    'NO'
                  )
                "
                (change)="
                  updateContractProductPropertyBool(
                    'FIBER WIFI ROUTER RENTAL',
                    'YES',
                    'NO',
                    $event
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['YES', 'NO']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="section-title">[解約情報]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">
              Will you be moving from your current residence?
            </td>
            <td>
              <select
                name="aConvinientDayOfTheWeek"
                [value]="
                  getContractProductPropertyBool(
                    'FIBER CANCEL MOVING RESIDENCE',
                    'YES',
                    'NO'
                  )
                "
                (change)="
                  updateContractProductPropertyBool(
                    'FIBER CANCEL MOVING RESIDENCE',
                    'YES',
                    'NO',
                    $event
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['YES', 'NO']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Date you will leave your current residence
            </td>
            <td>
              <input
                matInput
                [matDatepicker]="fiberCancelLeaveResidenceDatePicker"
                placeholder="none"
                [value]="
                  getContractProductPropertyDate(
                    'FIBER CANCEL LEAVE RESIDENCE DATE'
                  )
                "
                (dateChange)="
                  updateContractProductPropertyDate(
                    'FIBER CANCEL LEAVE RESIDENCE DATE',
                    $event
                  )
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="fiberCancelLeaveResidenceDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #fiberCancelLeaveResidenceDatePicker
              ></mat-datepicker>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Will you move in Japan or move overseas?
            </td>
            <td>
              <select
                name="aConvinientDayOfTheWeek"
                [value]="
                  getContractProductPropertyBool(
                    'FIBER CANCEL MOVE IN JAPAN OR NOT',
                    'YES',
                    'NO'
                  )
                "
                (change)="
                  updateContractProductPropertyBool(
                    'FIBER CANCEL MOVE IN JAPAN OR NOT',
                    'YES',
                    'NO',
                    $event
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['YES', 'NO']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Return of the Rental Devices (2 options)
            </td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString(
                    'FIBER CANCEL RETURNING METHOD'
                  )
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CANCEL RETURNING METHOD',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Shipping address for the Return Kit
            </td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER CANCEL KIT POST CODE')
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CANCEL KIT POST CODE',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">JP</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString(
                    'FIBER CANCEL KIT ADDRESS 1 JP'
                  )
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CANCEL KIT ADDRESS 1 JP',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">EN</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString(
                    'FIBER CANCEL KIT ADDRESS 1 EN'
                  )
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CANCEL KIT ADDRESS 1 EN',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Street</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER CANCEL KIT ADDRESS 2')
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CANCEL KIT ADDRESS 2',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Building</td>
            <td>
              <input
                class="full-width"
                type="text"
                [value]="
                  getContractProductPropertyString('FIBER CANCEL KIT ADDRESS 3')
                "
                (change)="
                  updateContractProductProperty(
                    'FIBER CANCEL KIT ADDRESS 3',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">NTT解約処理</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">ISP解約処理</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">レンタル品返送</td>
            <td></td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <!-- ### SECTION ### -->
          <tr>
            <td class="section-title">[オーダー処理]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">やり取りTicket No.</td>
            <td>
              <input
                type="text"
                value="{{ getFiberScheduleString('ticket_1') }}"
                (change)="setFiberScheduleString('ticket_1', $event)"
              />
              <a
                href="https://sakuramobile.zendesk.com/agent/tickets/{{
                  getFiberScheduleString('ticket_1')
                }}"
                target="_blank"
                >リンク</a
              >
            </td>
          </tr>
          <tr>
            <td class="item-title right">その他Ticket</td>
            <td>
              <input
                type="text"
                value="{{ getFiberScheduleString('ticket_2') }}"
                (change)="setFiberScheduleString('ticket_2', $event)"
              />
              <a
                href="https://sakuramobile.zendesk.com/agent/tickets/{{
                  getFiberScheduleString('ticket_2')
                }}"
                target="_blank"
                >リンク</a
              >
            </td>
          </tr>
          <tr>
            <td class="item-title right">その他Ticket</td>
            <td>
              <input
                type="text"
                value="{{ getFiberScheduleString('ticket_3') }}"
                (change)="setFiberScheduleString('ticket_3', $event)"
              />
              <a
                href="https://sakuramobile.zendesk.com/agent/tickets/{{
                  getFiberScheduleString('ticket_3')
                }}"
                target="_blank"
                >リンク</a
              >
            </td>
          </tr>
          <tr>
            <td class="item-title right vcenter">対応予定日</td>
            <td>
              <input
                matInput
                [matDatepicker]="supportDateAtDatePicker"
                placeholder="none"
                [value]="supportDateAtDate"
                (dateChange)="setFiberScheduleDate('support_date_at', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="supportDateAtDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #supportDateAtDatePicker></mat-datepicker>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="section-title">[NTTシステム登録内容]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">NTT東西</td>
            <td>
              <select
                name="ntt_region_selected"
                [value]="getFiberScheduleString('ntt_region')"
                (change)="setFiberScheduleSelectOption('ntt_region', $event)"
              >
                <option
                  *ngFor="let candidate of ['---', 'NTT東', 'NTT西']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">CAF</td>
            <td>
              <input
                type="text"
                [value]="getFiberScheduleString('ntt_caf')"
                (change)="setFiberScheduleString('ntt_caf', $event)"
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">CB</td>
            <td>
              <input
                type="text"
                [value]="getFiberScheduleString('ntt_cb')"
                (change)="setFiberScheduleString('ntt_cb', $event)"
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">顧客属性</td>
            <td>
              <select
                name="customer_attribute_selected"
                [value]="getFiberScheduleString('customer_attribute')"
                (change)="
                  setFiberScheduleSelectOption('customer_attribute', $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '新規',
                      'SMユーザー',
                      'JET新規',
                      'JET&SM',
                      '固定回線コード',
                      '引越し'
                    ]
                  "
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">住所</td>
            <td>
              <textarea
                class="large"
                [value]="getFiberScheduleString('ntt_system_inst_address')"
                (change)="setNttAddress($event)"
              >
              </textarea>
            </td>
          </tr>
          <tr>
            <td class="item-title right">プラン</td>
            <td>
              <select
                name="fiber_plan_type_select"
                (change)="
                  setContractProductPropertyString('FIBER PLAN TYPE', $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      'マンションSHS / 1 Gbps Max speed',
                      'マンションVDSL / 100 Mbps Max speed',
                      'マンションタイプ / 10 Gbps Max Speed',
                      'ファミリータイプ / 1 Gbps Max speed',
                      'ファミリータイプ / 10 Gbps Max speed'
                    ]
                  "
                  [value]="candidate"
                  [selected]="
                    candidate ===
                    getContractProductPropertyString('FIBER PLAN TYPE')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">工事タイプ</td>
            <td>
              <select
                name="fiber_installation_type_select"
                (change)="
                  setContractProductPropertyString(
                    'FIBER INSTALLATION TYPE',
                    $event
                  )
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      'Physical Installation',
                      'Remote Installation'
                    ]
                  "
                  [value]="candidate"
                  [selected]="
                    candidate ===
                    getContractProductPropertyString('FIBER INSTALLATION TYPE')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">現場調査</td>
            <td>
              <select
                name="investigation_required_selected"
                (change)="
                  setFiberScheduleSelectOption('investigation_required', $event)
                "
              >
                <option
                  *ngFor="let candidate of ['---', 'あり', 'なし']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('investigation_required')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">コンセント確認(リモート)</td>
            <td>
              <select
                name="optical_plug_confirmed_selected"
                (change)="
                  setFiberScheduleSelectOption('optical_plug_confirmed', $event)
                "
              >
                <option
                  *ngFor="let candidate of ['---', '済み', '未了']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('optical_plug_confirmed')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="section-title">提案工事日程（予約中）</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right vcenter">Preliminary Investigation</td>
            <td>
              <input
                matInput
                [matDatepicker]="preliminaryInvestigationDatePicker"
                placeholder="none"
                [value]="preliminaryInvestigationDate"
                (dateChange)="
                  setFiberScheduleDtDate('preliminary_investigation_dt', $event)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="preliminaryInvestigationDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #preliminaryInvestigationDatePicker
              ></mat-datepicker>
              <select
                name="preliminary_investigation_dt"
                (change)="
                  setFiberScheduleDtTime('preliminary_investigation_dt', $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '9-12',
                      '13-17',
                      '9-10',
                      '10-12',
                      '13-14',
                      '13-15',
                      '15-17'
                    ]
                  "
                  [value]="candidate"
                  [selected]="candidate === preliminaryInvestigationTime"
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_preliminary_investigation_dt"
                (click)="
                  clearFiberScheduleDt('preliminary_investigation_dt', $event)
                "
              >
                Clear
              </button>
            </td>
          </tr>
          <tr>
            <td class="item-title right vcenter">Device(s) Delivery</td>
            <td>
              <input
                matInput
                [matDatepicker]="deviceDeliveryDatePicker"
                placeholder="none"
                [value]="deviceDeliveryDtDate"
                (dateChange)="
                  setFiberScheduleDtDate('device_delivery_dt', $event)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="deviceDeliveryDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #deviceDeliveryDatePicker></mat-datepicker>
              <select
                name="device_delivery_dt_time"
                (change)="setFiberScheduleDtTime('device_delivery_dt', $event)"
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '8-12',
                      '14-16',
                      '16-18',
                      '18-20',
                      '19-21'
                    ]
                  "
                  [value]="candidate"
                  [selected]="candidate === deviceDeliveryDtTime"
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_device_delivery_dt"
                (click)="clearFiberScheduleDt('device_delivery_dt', $event)"
              >
                Clear
              </button>
            </td>
            <td>
              {{ getFiberScheduleString("ない") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right vcenter">Installation</td>
            <td>
              <input
                matInput
                [matDatepicker]="installationDatePicker"
                placeholder="none"
                [value]="installDtDate"
                (dateChange)="setFiberScheduleDtDate('install_dt', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="installationDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #installationDatePicker></mat-datepicker>
              <select
                name="install_dt"
                (change)="setFiberScheduleDtTime('install_dt', $event)"
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '9-12',
                      '13-17',
                      '9-10',
                      '10-12',
                      '13-14',
                      '13-15',
                      '15-17'
                    ]
                  "
                  [value]="candidate"
                  [selected]="candidate === installDtTime"
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_install_dt"
                (click)="clearFiberScheduleDt('install_dt', $event)"
              >
                Clear
              </button>
            </td>
          </tr>
          <tr>
            <td class="item-title right vcenter">スケジュール有効期限</td>
            <td>
              <input
                matInput
                [matDatepicker]="deadlineDateAtDatePicker"
                placeholder="none"
                [value]="deadlineDateAtDate"
                (dateChange)="setDeadlineDateAtDate($event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="deadlineDateAtDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #deadlineDateAtDatePicker></mat-datepicker>
              <select
                name="install_dt"
                (change)="setDeadlineDateAtTime($event)"
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '10AM',
                      '12PM',
                      '3PM',
                      '5PM',
                      '7PM'
                    ]
                  "
                  [value]="candidate"
                  [selected]="candidate === deadlineDateAtTime"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="item-title" colspan="2">
              Other Available Preliminary Investigation date/time
            </td>
          </tr>
          <tr>
            <td class="item-title right">希望日</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              1: {{ getFiberScheduleDtDate("schedule_opid_1") }}
              {{ getFiberScheduleDtTime("schedule_opid_1") }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              2: {{ getFiberScheduleDtDate("schedule_opid_2") }}
              {{ getFiberScheduleDtTime("schedule_opid_2") }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              3: {{ getFiberScheduleDtDate("schedule_opid_3") }}
              {{ getFiberScheduleDtTime("schedule_opid_3") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">提案候補日</td>
            <td></td>
          </tr>
          <tr *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
            <td class="section-title right vcenter">{{ i }} :</td>
            <td>
              <input
                matInput
                [matDatepicker]="otherInvestigationDatePicker"
                placeholder="none"
                [value]="
                  getFiberScheduleDateCandidateDtDate(
                    'schedule_opid_candidates',
                    i - 1
                  )
                "
                (dateChange)="
                  setFiberScheduleDateCandidateDtDate(
                    'schedule_opid_candidates',
                    i - 1,
                    $event
                  )
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="otherInvestigationDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #otherInvestigationDatePicker></mat-datepicker>
              <select
                name="setFiberScheduleDateCandidateDtTime"
                (change)="
                  setFiberScheduleDateCandidateDtTime(
                    'schedule_opid_candidates',
                    i - 1,
                    $event
                  )
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '9-12',
                      '13-17',
                      '9-10',
                      '10-12',
                      '13-14',
                      '13-15',
                      '15-17'
                    ]
                  "
                  [value]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleDateCandidateDtTime(
                      'schedule_opid_candidates',
                      i - 1
                    )
                  "
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_schedule_opid_candidates_{{ i - 1 }}"
                (click)="
                  clearFiberScheduleDateCandidateDt(
                    'schedule_opid_candidates',
                    i - 1,
                    $event
                  )
                "
              >
                Clear
              </button>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="item-title" colspan="2">
              Other Available Installation date/time
            </td>
          </tr>
          <tr>
            <td class="item-title right">希望日</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              1: {{ getFiberScheduleDtDate("schedule_oid_1") }}
              {{ getFiberScheduleDtTime("schedule_oid_1") }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              2: {{ getFiberScheduleDtDate("schedule_oid_2") }}
              {{ getFiberScheduleDtTime("schedule_oid_2") }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              3: {{ getFiberScheduleDtDate("schedule_oid_3") }}
              {{ getFiberScheduleDtTime("schedule_oid_3") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">提案候補日</td>
            <td></td>
          </tr>
          <tr *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
            <td class="section-title right vcenter">{{ i }} :</td>
            <td>
              <input
                matInput
                [matDatepicker]="otherInstallationDatePicker"
                placeholder="none"
                [value]="
                  getFiberScheduleDateCandidateDtDate(
                    'schedule_oid_candidates',
                    i - 1
                  )
                "
                (dateChange)="
                  setFiberScheduleDateCandidateDtDate(
                    'schedule_oid_candidates',
                    i - 1,
                    $event
                  )
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="otherInstallationDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #otherInstallationDatePicker></mat-datepicker>
              <select
                name="setFiberScheduleDateCandidateDtTime"
                (change)="
                  setFiberScheduleDateCandidateDtTime(
                    'schedule_oid_candidates',
                    i - 1,
                    $event
                  )
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '9-12',
                      '13-17',
                      '9-10',
                      '10-12',
                      '13-14',
                      '13-15',
                      '15-17'
                    ]
                  "
                  [value]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleDateCandidateDtTime(
                      'schedule_oid_candidates',
                      i - 1
                    )
                  "
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_schedule_opid_candidates_{{ i - 1 }}"
                (click)="
                  clearFiberScheduleDateCandidateDt(
                    'schedule_oid_candidates',
                    i - 1,
                    $event
                  )
                "
              >
                Clear
              </button>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="section-title">確定工事日程</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right vcenter">Preliminary Investigation</td>
            <td>
              <input
                matInput
                [matDatepicker]="preliminaryInvestigationDateFixedPicker"
                placeholder="none"
                [value]="preliminaryInvestigationFixedDate"
                (dateChange)="
                  setFiberScheduleDtDate(
                    'preliminary_investigation_dt_fixed',
                    $event
                  )
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="preliminaryInvestigationDateFixedPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #preliminaryInvestigationDateFixedPicker
              ></mat-datepicker>
              <select
                name="preliminary_investigation_dt_fixed"
                (change)="
                  setFiberScheduleDtTime(
                    'preliminary_investigation_dt_fixed',
                    $event
                  )
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '9-12',
                      '13-17',
                      '9-10',
                      '10-12',
                      '13-14',
                      '13-15',
                      '15-17'
                    ]
                  "
                  [value]="candidate"
                  [selected]="candidate === preliminaryInvestigationFixedTime"
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_preliminary_investigation_dt_fixed"
                (click)="
                  clearFiberScheduleDt(
                    'preliminary_investigation_dt_fixed',
                    $event
                  )
                "
              >
                Clear
              </button>
            </td>
          </tr>
          <tr>
            <td class="item-title right vcenter">Device(s) Delivery</td>
            <td>
              <input
                matInput
                [matDatepicker]="deviceDeliveryDateFixedPicker"
                placeholder="none"
                [value]="deviceDeliveryDtFixedDate"
                (dateChange)="
                  setFiberScheduleDtDate('device_delivery_dt_fixed', $event)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="deviceDeliveryDateFixedPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #deviceDeliveryDateFixedPicker></mat-datepicker>
              <select
                name="device_delivery_dt_fixed"
                (change)="
                  setFiberScheduleDtTime('device_delivery_dt_fixed', $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '8-12',
                      '14-16',
                      '16-18',
                      '18-20',
                      '19-21'
                    ]
                  "
                  [value]="candidate"
                  [selected]="candidate === deviceDeliveryDtFixedTime"
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_device_delivery_dt_fixed"
                (click)="
                  clearFiberScheduleDt('device_delivery_dt_fixed', $event)
                "
              >
                Clear
              </button>
            </td>
          </tr>
          <tr>
            <td class="item-title right vcenter">Installation</td>
            <td>
              <input
                matInput
                [matDatepicker]="installationDateFixedPicker"
                placeholder="none"
                [value]="installDtFixedDate"
                (dateChange)="
                  setFiberScheduleDtDate('install_dt_fixed', $event)
                "
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="installationDateFixedPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #installationDateFixedPicker></mat-datepicker>
              <select
                name="install_dt"
                (change)="setFiberScheduleDtTime('install_dt_fixed', $event)"
              >
                <option
                  *ngFor="
                    let candidate of [
                      '---',
                      '9-12',
                      '13-17',
                      '9-10',
                      '10-12',
                      '13-14',
                      '13-15',
                      '15-17'
                    ]
                  "
                  [value]="candidate"
                  [selected]="candidate === installDtFixedTime"
                >
                  {{ candidate }}
                </option>
              </select>
              <button
                id="clear_install_dt_fixed"
                (click)="clearFiberScheduleDt('install_dt_fixed', $event)"
              >
                Clear
              </button>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="section-title">工事費用</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">Administrative Fee</td>
            <td>
              <input
                type="checkbox"
                id="inst_fee_administration_check"
                (change)="updateFiberSchedulePaymentSumFee()"
                checked
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <input
                type="text"
                list="inst_fee_administration_types"
                [value]="getFiberScheduleString('inst_fee_administration')"
                (change)="
                  setFiberScheduleSelectOption(
                    'inst_fee_administration',
                    $event
                  )
                "
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <datalist id="inst_fee_administration_types">
                <option value="5000">5000</option>
                <option value="100">100</option>
              </datalist>
              <select
                id="inst_fee_administration_selected"
                name="inst_fee_administration_selected"
                (change)="
                  setFiberScheduleSelectOption(
                    'inst_fee_administration_status',
                    $event
                  )
                "
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '支払済', '請求中', '未請求']
                  "
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('inst_fee_administration_status')
                  "
                  [disabled]="
                    data.fiber_schedule.inst_fee_pay_status === '支払済'
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Installation Fee</td>
            <td>
              <input
                type="checkbox"
                id="inst_fee_install_check"
                (change)="updateFiberSchedulePaymentSumFee()"
                checked
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <input
                type="text"
                list="inst_fee_install_types"
                [value]="getFiberScheduleString('inst_fee_install')"
                (change)="
                  setFiberScheduleSelectOption('inst_fee_install', $event)
                "
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <datalist id="inst_fee_install_types">
                <option value="3000"></option>
                <option value="12000"></option>
                <option value="20000"></option>
              </datalist>
              <select
                id="inst_fee_install_selected"
                name="inst_fee_install_selected"
                (change)="
                  setFiberScheduleSelectOption(
                    'inst_fee_install_status',
                    $event
                  )
                "
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '支払済', '請求中', '未請求']
                  "
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('inst_fee_install_status')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Weekend / Holiday Installation</td>
            <td>
              <input
                type="checkbox"
                id="inst_fee_holiday_check"
                (change)="updateFiberSchedulePaymentSumFee()"
                checked
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <input
                type="text"
                list="inst_fee_holiday_types"
                [value]="getFiberScheduleString('inst_fee_holiday')"
                (change)="
                  setFiberScheduleSelectOption('inst_fee_holiday', $event)
                "
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <datalist id="inst_fee_holiday_types">
                <option value="3000">3000</option>
              </datalist>
              <select
                id="inst_fee_holiday_selected"
                name="inst_fee_holiday_selected"
                (change)="
                  setFiberScheduleSelectOption(
                    'inst_fee_holiday_status',
                    $event
                  )
                "
                [disabled]="
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '支払済', '請求中', '未請求']
                  "
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('inst_fee_holiday_status')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Deposit(2 Month) for Cash Payment</td>
            <td>
              <input
                type="checkbox"
                id="inst_fee_cash_deposit_check"
                (change)="updateFiberSchedulePaymentSumFee()"
                [checked]="
                  getContractPropertyString('PAY METHOD') === 'SMARTPIT'
                "
                [disabled]="
                  getContractPropertyString('PAY METHOD') !== 'SMARTPIT' ||
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <input
                type="text"
                id="inst_fee_cash_deposit_value"
                list="inst_fee_cash_deposit_types"
                [value]="getFiberScheduleString('inst_fee_cash_deposit')"
                (change)="
                  setFiberScheduleSelectOption('inst_fee_cash_deposit', $event)
                "
                [disabled]="
                  getContractPropertyString('PAY METHOD') !== 'SMARTPIT' ||
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              />
              <datalist id="inst_fee_cash_deposit_types">
                <option value="12960">12960</option>
                <option value="17960">17960</option>
              </datalist>
              <select
                id="inst_fee_cash_deposit_selected"
                name="inst_fee_cash_deposit_selected"
                (change)="
                  setFiberScheduleSelectOption(
                    'inst_fee_cash_deposit_status',
                    $event
                  )
                "
                [disabled]="
                  getContractPropertyString('PAY METHOD') !== 'SMARTPIT' ||
                  data.fiber_schedule.inst_fee_pay_status === '支払済'
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '支払済', '請求中', '未請求']
                  "
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('inst_fee_cash_deposit_status')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Total</td>
            <td>
              <span id="fiber_schedule_fee_total_no_tax"></span>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Total(税込み)</td>
            <td>
              <span id="fiber_schedule_fee_total_with_tax"></span>
            </td>
          </tr>
          <tr>
            <td class="item-title right">支払い方法</td>
            <td>
              {{ getContractPropertyString("PAY METHOD") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">スマートピット番号</td>
            <td>
              {{ getContractPropertyString("SMARTPIT NUMBER") }}
            </td>
          </tr>
          <tr>
            <td class="item-title right">支払いステータス</td>
            <td>
              <select
                id="inst_fee_pay_status_selected"
                name="inst_fee_pay_status_selected"
                (change)="
                  setFiberScheduleSelectOption('inst_fee_pay_status', $event)
                "
              >
                <option
                  *ngFor="
                    let candidate of ['---', '支払済', '請求中', '未請求']
                  "
                  [ngValue]="candidate"
                  [selected]="
                    candidate === getFiberScheduleString('inst_fee_pay_status')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              <div
                *ngIf="getContractPropertyString('PAY METHOD') === 'SMARTPIT'"
              >
                <button
                  (click)="onClickExportSmartPitCSV($event)"
                  [disabled]="
                    data.fiber_schedule.inst_fee_pay_status === '支払済'
                  "
                >
                  SMARTPIT CSV
                </button>
              </div>
              <div
                *ngIf="
                  getContractPropertyString('PAY METHOD') === 'CREDIT CARD'
                "
              >
                <button
                  (click)="onClickChargeCredit($event)"
                  [disabled]="
                    data.fiber_schedule.inst_fee_pay_status === '支払済'
                  "
                >
                  CREDIT CHARGE
                </button>
              </div>
            </td>
            <td>
              支払日 : {{ getFiberScheduleDateAsString("inst_fee_pay_date")
              }}<br />
              エラー : {{ getFiberScheduleString("inst_fee_pay_error") }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="item-title right">
              Phone number (Important) - Final confirmation
            </td>
            <td>
              <input
                type="text"
                value="{{
                  getFiberScheduleString('contact_phone_number')
                }}"
                (change)="
                  setFiberScheduleText('contact_phone_number', $event)
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">自分で連絡するかどうか</td>
            <td>
              <select
                name="note_installation_handler_option_selected"
                (change)="
                  setFiberScheduleSelectOption(
                    'note_installation_handler_option',
                    $event
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', 'SakuraMobile', 'Customer']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleString('note_installation_handler_option')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              Residence’s management company/Landlord name
            </td>
            <td>
              <input
                type="text"
                value="{{
                  getFiberScheduleString('note_residence_company_name')
                }}"
                (change)="
                  setFiberScheduleText('note_residence_company_name', $event)
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Contact information (phone number)</td>
            <td>
              <input
                type="text"
                value="{{
                  getFiberScheduleString('note_residence_contact_info')
                }}"
                (change)="
                  setFiberScheduleText('note_residence_contact_info', $event)
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Will inform later</td>
            <td>
              <select
                name="note_will_inform_later_selected"
                (change)="
                  setFiberScheduleBool(
                    'note_will_inform_later',
                    $event,
                    'YES',
                    'NO'
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', 'YES', 'NO']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleBool('note_will_inform_later', 'YES', 'NO')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              If you know Company representative’s name
            </td>
            <td>
              <input
                type="text"
                value="{{
                  getFiberScheduleString('note_rental_company_representive')
                }}"
                (change)="
                  setFiberScheduleText(
                    'note_rental_company_representive',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">
              I am unsure of the building management company’s information.
            </td>
            <td>
              <select
                name="note_unsure_rental_company_selected"
                (change)="
                  setFiberScheduleBool(
                    'note_unsure_rental_company',
                    $event,
                    'Unsure',
                    'Sure'
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', 'Unsure', 'Sure']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleBool(
                      'note_unsure_rental_company',
                      'Unsure',
                      'Sure'
                    )
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Company’s/Organization’s Name</td>
            <td>
              <input
                type="text"
                value="{{ getFiberScheduleString('note_rental_company_name') }}"
                (change)="
                  setFiberScheduleText('note_rental_company_name', $event)
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Supervisor’s/Human Resources’ name</td>
            <td>
              <input
                type="text"
                value="{{
                  getFiberScheduleString('note_rental_supervisors_name')
                }}"
                (change)="
                  setFiberScheduleText('note_rental_supervisors_name', $event)
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">Contact information</td>
            <td>
              <input
                type="text"
                value="{{
                  getFiberScheduleString('note_rental_supervisors_contact_info')
                }}"
                (change)="
                  setFiberScheduleText(
                    'note_rental_supervisors_contact_info',
                    $event
                  )
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">不動産屋への連絡方法</td>
            <td>
              <textarea
                class="large"
                [value]="getFiberScheduleString('inst_fee_note')"
                (change)="setFiberScheduleText('inst_fee_note', $event)"
              >
              </textarea>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="section-title">[工事前]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">ISP＆事前連絡</td>
            <td>
              <input
                type="text"
                list="isp_types"
                [value]="getFiberScheduleString('isp_notification_status')"
                (change)="
                  setFiberScheduleSelectOption(
                    'isp_notification_status',
                    $event
                  )
                "
              />
              <datalist id="isp_types">
                <option value="ぷらら">ぷらら</option>
                <option value="OCNビジネス">OCNビジネス</option>
                <option value="NTT PC">NTT PC</option>
                <option value="Asahi Net">Asahi Net  </option>
              </datalist>
            </td>
          </tr>
          <tr>
            <td class="item-title right">ID(PPPoE)</td>
            <td>
              <input
                type="text"
                [value]="getFiberScheduleString('ppp_o_e_id')"
                (change)="setFiberScheduleText('ppp_o_e_id', $event)"
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">PW(PPPoE)</td>
            <td>
              <input
                type="text"
                [value]="getFiberScheduleString('ppp_o_e_pwd')"
                (change)="setFiberScheduleText('ppp_o_e_pwd', $event)"
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">備考</td>
            <td>
              <input
                type="text"
                [value]="getFiberScheduleString('isp_note')"
                (change)="setFiberScheduleText('isp_note', $event)"
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">登録</td>
            <td>
              <select
                name="isp_registration_selected"
                (change)="
                  setFiberScheduleBoolForISPAndNotification(
                    'isp_registration',
                    $event,
                    '済',
                    '未'
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', '済', '未']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleBool('isp_registration', '済', '未')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">事前連絡</td>
            <td>
              <select
                name="inst_reminder_notified"
                (change)="
                  setFiberScheduleBoolForISPAndNotification(
                    'inst_reminder_notified',
                    $event,
                    '済',
                    '未'
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', '済', '未']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleBool('inst_reminder_notified', '済', '未')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="item-title right">Wireless Router発送手配</td>
            <td>
              <select
                name="router_shipment_arrangement_selected"
                (change)="
                  setFiberScheduleBool(
                    'router_shipment_arrangement',
                    $event,
                    '済',
                    '未'
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', '済', '未']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleBool(
                      'router_shipment_arrangement',
                      '済',
                      '未'
                    )
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">機種</td>
            <td>
              <input
                type="text"
                list="router_types"
                name="router_model_select"
                [value]="getFiberScheduleString('router_model')"
                (change)="setFiberScheduleSelectOption('router_model', $event)"
              />
              <datalist id="router_types">
                <option
                  *ngFor="
                    let candidate of [
                      'TP0001',
                      'TP0002',
                      'TP0003',
                      'TP0004',
                      'TP0005',
                      'TP0006'
                    ]
                  "
                  [ngValue]="candidate"
                  [selected]="
                    candidate === getFiberScheduleString('router_model')
                  "
                >
                  {{ candidate }}
                </option>
              </datalist>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Delivery Company</td>
            <td>
              <select
                name="delivery_companies_selected"
                [value]="getFiberScheduleString('router_delivery_company')"
                (change)="
                  setFiberScheduleSelectOption(
                    'router_delivery_company',
                    $event
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', 'Yamato', 'Sagawa']"
                  [ngValue]="candidate"
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">Tracking number</td>
            <td>
              <input
                type="text"
                [value]="getFiberScheduleString('router_tracking_number')"
                (change)="
                  setFiberScheduleText('router_tracking_number', $event)
                "
              />
            </td>
          </tr>
          <tr>
            <td class="item-title right">発送連絡</td>
            <td>
              <select
                name="shipment_notified"
                (change)="
                  setFiberScheduleBool('shipment_notified', $event, '済', '未')
                "
              >
                <option
                  *ngFor="let candidate of ['---', '済', '未']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleBool('shipment_notified', '済', '未')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td></td>
          </tr>
          <!-- ### SECTION ### -->
          <tr>
            <td class="section-title">[工事後]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">開通確認メール</td>
            <td>
              <select
                name="connection_confirmed"
                (change)="
                  setFiberScheduleBool(
                    'connection_confirmed',
                    $event,
                    '済',
                    '未'
                  )
                "
              >
                <option
                  *ngFor="let candidate of ['---', '済', '未']"
                  [ngValue]="candidate"
                  [selected]="
                    candidate ===
                    getFiberScheduleBool('connection_confirmed', '済', '未')
                  "
                >
                  {{ candidate }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td class="item-title right">接続トラブル</td>
            <td>
              <textarea
                class="large"
                [value]="getFiberScheduleString('connection_problem_note')"
                (change)="
                  setFiberScheduleString('connection_problem_note', $event)
                "
              >
              </textarea>
            </td>
          </tr>
          <tr>
            <td class="section-title">[引越]</td>
            <td></td>
          </tr>
          <tr>
            <td class="item-title right">引越し時ノート</td>
            <td>
              <textarea
                class="large"
                [value]="getFiberScheduleString('moving_note')"
                (change)="setFiberScheduleString('moving_note', $event)"
              >
              </textarea>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <p>fiber-schedule works!</p>
</div>
