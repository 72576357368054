import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatCheckboxChange,
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material';
import { ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Utils } from '../../../helpers';
import { AuthService } from '../../../services/auth';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { ImageDialogComponent } from '../../dialog/image-dialog/image-dialog.component';
import { Url } from 'src/app/constants';
import { InactiveUsersResponse } from '../list-activate/list-activate.component';
import { Observable, forkJoin, of } from 'rxjs';
import { delay, concatMap } from 'rxjs/operators';

export class Contract {
  id: number;
  contract_code: string;
  status: boolean;
  start_at: Date;
  start_at_string: string;
  end_at: Date;
  end_at_string: string;
}

export class DeviceCode1 {
  id: number;
  contract_product_id: number;
  product_property_id: number;
  product_property_type_id: number;
  product_type_id: number;
  show_order: number;
  show_status: boolean;
  title: string;
  updated_by_user_id: number;
  updated_at: string;
  key: string;
  value: string;
}

export class ChargeProductItem {
  id: number;
  contract_id: number;
  description: string;
  end_at: string;
  end_at_updated_at: string;
  end_at_updated_by_user_id: number;
  invoice_sender_key: string;
  product_type_id: number;
  return_check_date: string;
  return_checker_user_id: number;
  return_date: string;
  show_order: number;
  start_at: string;
  start_at_updated_at: string;
  start_at_updated_by_user_id: number;
  title: string;
  device_code_1: DeviceCode1;
  telno: string;
  line_pause: boolean;
  name?: string;
}

export class ChargeListItem {
  id: number; // invoice_id
  invoice_month: string;
  invoice_code: string;
  contract_id: number;
  contract_code: string;
  contract_end_date: string; // for sort only
  end_at: Date;
  end_at_string: string;
  user_id: number;
  user_e_mail: string;
  user_name_id: number;
  user_name: string;
  mail_send_status: number;
  mail_send_config: number;
  pay_method: string;
  demand_status: number;
  demand_send_user_id: number;
  demand_sent_at: string;
  pay_status: number;
  pay_error: string;
  price_total: number;
  price_tax: number;
  products_display_string: string;
  products: Array<ChargeProductItem>;
  special_case: boolean;
  is_selected: boolean;    // for user interface
  unpaid_count: number;
  telno_count: number;
  telno: string | null;
  line_pause?: number | string;
  displayedSuccessCount: number = 0;
  some_other_property: string;
  name?: string;
  selectedCount: number = 0;
  invoice: {
    is_selected: boolean;
  };
  constructor() {
    this.is_selected = false;
  }
}

export class PayMethod {
  public key: string;
  public title: string;
}

export class DemandLevel {
  public key: string;
  public title: string;
}

@Component({
  selector: 'app-list-charges',
  templateUrl: './list-charges.component.html',
  styleUrls: ['./list-charges.component.scss']
})
export class ListChargesComponent implements OnInit {

  public root_for_pdf: string;
  public unpaid_only: boolean;
  public invoice_month: string;
  public selecting_pay_method: PayMethod;
  public pay_methods: Array<PayMethod>;
  public selecting_demand_level: DemandLevel;
  public demand_levels: Array<DemandLevel>;
  public demand_send_button_disabled: boolean;
  public interruption_button_disabled: boolean;
  public filter_key_up_disabled: boolean = true;
  public charge_button_disabled: boolean;
  public isCheckedOne = true;
  public isChecked = true;
  public allData: any[] = [];
  public originalData: any[] = [];
  public pay_method: any;
  public telno_count: string | null;
  public telno: number;
  public pay_status: 'paid' | 'unpaid' | 'all';
  public info_type: string;
  public info: string = '';
  public status: "pause" | "resume";
  public from_paydate: string;
  public to_paydate: string;
  public searchFromPayDate: string = '';
  public searchToPayDate: string = '';
  public selectedStatus: string = 'all';
  public search_start_error: boolean = false;
  public search_end_error: boolean = false;
  public maxStartDate: Date = new Date();
  public successCount: number = 0;
  public displayedSuccessCount: number | null = null;
  public pay_date: string;
  public filteredInvoices: any[] = [];
  public isLoading: boolean = false;
  public device_code: string;
  public invoice_id: number;
  public isPaidOnlyChecked = false;
  public loading: boolean = true;
  public resetCheckBox: string;
  public checked_count: number = 0;
  public selectedCount: number = 0;
  public special_case: boolean;
  public special_case_checked: boolean;
  public filteredUsersCount: number;
  public previousData: any[] = [];
  public updateData: any[] = [];
  public previousUnpaidData: ChargeListItem[] = [];


  public invoices: Array<ChargeListItem>;
  public invoices_send_targets: Array<ChargeListItem>;
  public invoice_month_error: string;
  public display_columns: Array<string>;
  public data_source: MatTableDataSource<ChargeListItem>;
  public filter_text: string;
  public selection = new SelectionModel<ChargeListItem>(true, []);
  public current_filter: string;

  public select_send_all: boolean;
  public select_send_all_disabled: boolean;
  public select_send_all_indeterminate: boolean;
  public sent_invoice_codes: Array<string>;
  public sent_success_invoice_codes: Array<string>;
  public sent_fail_invoice_codes: Array<string>;
  protected _el: HTMLElement;
  public confirm_dialog_ref: any;

  constructor(
    protected el: ElementRef,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public cdr: ChangeDetectorRef,
  ) {
    this._el = el.nativeElement;
    this.select_send_all = false;
    this.select_send_all_disabled = true;
    this.select_send_all_indeterminate = false;
    this.unpaid_only = true;
    this.invoice_month = undefined;
    this.filter_text = '';
    this.pay_methods = new Array<PayMethod>();
    this.pay_methods.push({ key: 'CREDIT CARD', title: 'CREDIT CARD' });
    this.pay_methods.push({ key: 'SMARTPIT', title: 'SMARTPIT' });
    this.pay_methods.push({ key: 'PAYPAL', title: 'PAYPAL' });
    this.pay_methods.push({ key: 'PONTA', title: 'PONTA' });
    this.pay_methods.push({ key: 'GI BILL', title: 'GI BILL' });
    this.demand_levels = new Array<DemandLevel>();
    this.demand_levels.push({ key: '1', title: 'Lv 1 : 未払いの通知' });
    this.demand_levels.push({ key: '2', title: 'Lv 2 : サスペンドの警告' });
    this.demand_levels.push({ key: '3', title: 'Lv 3 : サスペンドの通知' });
    this.demand_levels.push({ key: '4', title: 'Lv 4 : 強制解約の通知(法的措置の通知)' });
    this.demand_send_button_disabled = true;
    this.charge_button_disabled = true;
    this._el = el.nativeElement;
    this.pay_status = 'unpaid',
      this.display_columns = [
        'is_selected',
        'contract_code',
        'invoice_code',
        // 'pdf',
        'products',
        'user_name',
        'pay_method',
        'pay_status',
        'unpaid_count',
        'interruption_confirmation',
        'pay_amount',
        'pay_error',
        'demand_level',
        'demand_sent_at',
        'pay_date',
        'contract_end_date',
        'special_case',
      ];
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    // <<< for debug environment
    if (location.href.indexOf('localhost:', 0) > 0) {
      const parts = location.href.split(':');
      this.root_for_pdf = parts[0] + ':' + parts[1] + ':3001';
    } else {
      this.root_for_pdf = '';
    }
    // >>> for debug environment
    if (!this.data_source) {
      this.data_source = new MatTableDataSource<ChargeListItem>();
    }
  }

  public onRefreshList(event: any): void {
    this.refresh();
  }

  public onUnpaidOnlyCheckChanged(event: MatCheckboxChange): void {
    // this.unpaid_only = event.checked;
    this.filter_key_up_disabled = true;
    this.current_filter = '';
    this.pay_status = event.checked ? 'unpaid' : 'all';
    this.isCheckedOne = event.checked;
    this.isChecked = event.checked;
    if (this.pay_status === 'unpaid') {
      this.interruption_button_disabled = false;
    } else if (this.pay_status === 'all') {
      this.interruption_button_disabled = true;
    }
    if (event.checked) {
      this.applyFilter('both_checked');
    } else {
      this.applyFilter('');
    }
    this.refresh();
  }

  public onPaidOnlyCheckChanged(event: MatCheckboxChange): void {
    this.filter_key_up_disabled = true;
    this.current_filter = '';
    this.pay_status = event.checked ? 'paid' : 'all';
    this.isCheckedOne = false;
    this.isChecked = false;
    if (event.checked) {
      this.applyFilter('');
      this.interruption_button_disabled = true;
    }
  }


  public fromPayDate(searchFromPayDate: string): void {
    console.log('formPayDate', searchFromPayDate)
    this.searchFromPayDate = searchFromPayDate;
    if (new Date(this.searchToPayDate) < new Date(this.searchFromPayDate)) {
      this.search_end_error = true;
    } else {
      this.search_end_error = false;
    }
  }

  public toPayDate(searchToPayDate: string): void {
    console.log('toPayDate', searchToPayDate)
    this.searchToPayDate = searchToPayDate;
    if (new Date(this.searchFromPayDate) > new Date(this.searchToPayDate)) {
      this.search_start_error = true;
    } else {
      this.search_start_error = false;
    }
  }


  public onInvoiceMonthChanged(invoice_month: string): void {
    this.invoice_month = invoice_month;
    this.refresh();
    this.updateDemandSendButtonDisabled();
  }

  public onChangedPayMethod(event: any): void {
    const filtered = this.pay_methods.filter(v => {
      return v.key === event.value;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.selecting_pay_method = filtered[0];
    }
    this.charge_button_disabled = this.selecting_pay_method.key !== 'CREDIT CARD';
    this.refresh();
    this.updateDemandSendButtonDisabled();
  }

  public onChangedDemandLevel(event: any): void {
    const filtered = this.demand_levels.filter(v => {
      return v.key === event.value;
    });
    if (Utils.isValue(filtered) && filtered.length > 0) {
      this.selecting_demand_level = filtered[0];
      console.log(this.selecting_demand_level);
    }
    this.updateDemandSendButtonDisabled();
  }

  countTelnos(products: any[]): number {
    if (Array.isArray(products)) {
      return products.filter(product => product.telno !== null && product.telno !== undefined).length;
    }
    return 0;
  }

  public resetState(): void {
    this.select_send_all = false;
    this.checked_count = 0;
    this.filteredInvoices = [];
  }


  private refresh(): void {
    if (!Utils.isValue(this.unpaid_only) || !Utils.isValue(this.invoice_month) || !Utils.isValue(this.selecting_pay_method)) {
      console.log('ListChargesComponent::skip refresh list');
      return;
    }
    this.loading = true;
    this.fromPayDate(this.searchFromPayDate);

    const param = {
      // telno_count: this.telno_count != null ? this.telno_count : 0,
      telno: this.telno != null ? this.telno : null,
      pay_status: this.pay_status,
      // unpaid_only: this.unpaid_only,
      from_paydate: this.searchFromPayDate,
      to_paydate: this.searchToPayDate,
      invoice_month: this.invoice_month,
      pay_method: this.selecting_pay_method.key,
    };

    this.applyFilter('unpaid_count_1');
    this.applyFilter('unpaid_count_2_or_more');
    this.filter_key_up_disabled = true;
    this.current_filter = '';
    this.auth_service.apiListCharges(param).subscribe(
      response => {
        this.invoices = <Array<ChargeListItem>>response.data;
        this.data_source = new MatTableDataSource(this.invoices);
        this.data_source.sort = this.sort;
        this.data_source.paginator = this.paginator;
        this.select_send_all_disabled = this.invoices.length > 0 ? false : true;
        this.select_send_all = false;
        this.select_send_all_disabled = true;
        this.invoices.forEach(invoice => {
          console.log(invoice);
          this.select_send_all = true;
          this.select_send_all_disabled = false;
          invoice.is_selected = true;
          invoice.products_display_string = this.productsString(invoice.products);
          invoice.telno_count = this.countTelnos(invoice.products);
          // if (Utils.isValue(invoice.contract.start_at)) {
          //   invoice.contract.start_at_string = Utils.getDisplayDateString(invoice.contract.start_at.toLocaleString(Utils.getHighestPriorityLocale()));
          // }
          if (Utils.isValue(invoice.end_at)) {
            invoice.end_at_string = Utils.getDisplayDateString(invoice.end_at.toLocaleString(Utils.getHighestPriorityLocale()));
            invoice.contract_end_date = invoice.end_at_string;
          }
        });
        this.loading = false;
        this.filter_key_up_disabled = false;
        if (this.pay_status === 'paid') {
          this.isCheckedOne = false;
          this.isChecked = false;
        }
        if (this.pay_status === 'unpaid') {
          this.isCheckedOne = true;
          this.isChecked = true;
        }
        console.log('選択されているユーザー:', this.invoices);
      },
      error => {
        console.log(error);
      }
    );
  }

  public onClickSendDemand(event: any): void {
    this.sent_invoice_codes = [];
    this.sent_success_invoice_codes = [];
    this.sent_fail_invoice_codes = [];
    const invoice_code_visibles = Array.from(this._el.querySelectorAll('td.mat-column-invoice_code a.invoice_code'));

    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '送信開始の確認',
        message: event.title + '督促メールの送信を開始します。よろしいですか？'
      }
    });

    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.invoices.forEach(invoice => {
            // const found_visible = invoice_code_visibles.filter( invoice_code => {
            //   return invoice_code.textContent === invoice.invoice_code;
            // });
            // if (found_visible.length > 0) {
            if (Utils.isValue(invoice.is_selected) && invoice.is_selected === true) {
              this.sent_invoice_codes.push(invoice.invoice_code);
              const param = {
                demand_level: parseInt(this.selecting_demand_level.key, 10),
                invoice_id: invoice.id,
              };
              this.auth_service.apiSendDemandLetter(param).subscribe(
                response => {
                  this.sent_success_invoice_codes.push(invoice.invoice_code);
                  if (response.message.title === 'Success') invoice.demand_status = param.demand_level;
                  console.log('sent= ' + this.sent_invoice_codes.length + ': success= ' + this.sent_success_invoice_codes.length + ': fail=' + this.sent_fail_invoice_codes.length);
                },
                error => {
                  this.sent_fail_invoice_codes.push(invoice.invoice_code);
                  console.log('sent= ' + this.sent_invoice_codes.length + ': success= ' + this.sent_success_invoice_codes.length + ': fail=' + this.sent_fail_invoice_codes.length);
                }
              );
            }
            // }
          });
        }
      }
    });
  }


  processPayDate(element: { pay_date: string | null | undefined }): string {
    if (element.pay_date) {
      const match = element.pay_date.match(/^\d{4}-\d{2}-\d{2}/);
      return match ? match[0] : '-';
    }
    return '-';
  }


  public openSendDemandHelpDialog(file_name: string): void {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '90%',
      height: '90%',
      hasBackdrop: true,
      data: {
        image: file_name
      }
    });
  }


  public onClickInterruptionDialog(): void {
    this.interruption_button_disabled = false;
    if (this.pay_status === 'paid' || this.pay_status === 'all') {
      this.interruption_button_disabled = true;
      console.log('解除操作は現在利用できません');
      return;
    }

    const filterValue = this.filter_text;
    this.updateData = this.generateUpdateData(this.previousData, filterValue)
    const updateDataCount = this.updateData.length;

    this.cdr.detectChanges();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '【回線中断の確認】',
        message: `選択された回線を"中断"します。<br>${updateDataCount}件実行されます。よろしいですか？`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.closeby === 'ok') {
        this.onClickInterruption();
      }
    });
  }
  //中断
  public onClickInterruption(): void {
    const filterValue = this.filter_text;
    let selectedItems = [];
    const filteredInvoicesSet = this.filteredInvoices.length > 0
      ? new Set(this.filteredInvoices.map(invoice => invoice.id)) : null
    if (filteredInvoicesSet) {
      const inFiltered = this.invoices.filter(invoice => filteredInvoicesSet.has(invoice.id))
      inFiltered.forEach(invoice => {
        if (invoice.is_selected) {
          selectedItems.push(invoice);
        }
      })
    } else {
      selectedItems = this.generateUpdateData(this.previousData, filterValue);
    }
    console.log("selectedItems>>:", selectedItems);
    if (selectedItems.length === 0) {
      console.log('選択されたユーザーがいません');
      alert('選択されたユーザーがいません。')
      return;
    }

    const requests = [];
    selectedItems.forEach(item => {
      item.products.forEach(product => {
        if (!product.telno || product.telno.trim() === '') {
          console.warn('telnoがnullまたはundefinedです。APIリクエストは送信されません。');
        } else {
          requests.push({
            info_type: 'telno',
            info: product.telno || '',
            status: 'pause',
          });
        }
      });
    });
    of(...requests)
      .pipe(
        concatMap(params =>
          this.auth_service.putApiLinePause(params).pipe(
            delay(1000)
          )
        )
      )
      .subscribe({
        next: response => {
          console.log('中断しました。', response);
        },
        error: error => {
          console.error('APIエラー:', error);
        },
        complete: () => {
          console.log('すべてのリクエストが完了しました。');
          alert('すべてのユーザーのリクエストが完了しました。')
        },
      });
    this.updateDemandSendButtonDisabled();
  }

  public onClickReleaseDialog(): void {
    this.interruption_button_disabled = false;
    if (this.pay_status === 'paid' || this.pay_status === 'all') {
      this.interruption_button_disabled = true;
      console.log('解除操作は現在利用できません');
      return;
    }
    const filterValue = this.filter_text;
    this.updateData = this.generateUpdateData(this.previousData, filterValue)
    const updateDataCount = this.updateData.length;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '30rem',
      hasBackdrop: true,
      data: {
        title: '【中断解除の確認】',
        message: `選択された回線を"解除"します。<br>${updateDataCount}件実行されます。よろしいですか？`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.closeby === 'ok') {
        this.onClickRelease();
      }
    });
  }

  //解除
  public onClickRelease(): void {
    const filterValue = this.filter_text;
    let selectedItems = [];
    const filteredInvoicesSet = this.filteredInvoices.length > 0
      ? new Set(this.filteredInvoices.map(invoice => invoice.id)) : null
    if (filteredInvoicesSet) {
      const inFiltered = this.invoices.filter(invoice => filteredInvoicesSet.has(invoice.id))
      inFiltered.forEach(invoice => {
        if (invoice.is_selected) {
          selectedItems.push(invoice);
        }
      })
    } else {
      selectedItems = this.generateUpdateData(this.previousData, filterValue);
    }
    console.log("selectedItems>>:", selectedItems);
    if (selectedItems.length === 0) {
      console.log('選択されたユーザーがいません');
      alert('選択されたユーザーがいません。')
      return;
    }

    const requests = [];
    selectedItems.forEach(item => {
      console.log(selectedItems);
      item.products.forEach(product => {
        if (!product.telno || product.telno.trim() === '') {
          console.warn('telnoがnullまたはundefinedです。APIリクエストは送信されません。');
        } else {
          requests.push({
            info_type: 'telno',
            info: product.telno || '',
            status: 'resume',
          });
        }
      });
    });
    of(...requests)
      .pipe(
        concatMap(params =>
          this.auth_service.putApiLinePause(params).pipe(
            delay(1000)
          )
        )
      )
      .subscribe({
        next: response => {
          console.log('解除しました。', response);
        },
        error: error => {
          console.error('APIエラー:', error);
        },
        complete: () => {
          console.log('すべてのリクエストが完了しました。');
          alert('すべてのユーザーのリクエストが完了しました。')
        },
      });
    this.updateDemandSendButtonDisabled();
  }


  private charge_recursive(current_invoice_index: number): void {
    if (current_invoice_index <= this.invoices.length) {
      let current_invoice = this.invoices[current_invoice_index];
      if (current_invoice && current_invoice.is_selected) {
        console.log("current_invoice : ", current_invoice);
        this.auth_service.apiChargeUnpaidInvoice({ invoice_id: current_invoice.id }).subscribe(
          response => {
            current_invoice.pay_status = response.pay_status;
            console.log(current_invoice.invoice_code + " PAY SUCCESS!");
            this.charge_recursive(current_invoice_index + 1);
          },
          error => {
            console.log(current_invoice.invoice_code + " PAY FAILED!");
            this.charge_recursive(current_invoice_index + 1);
          }
        )
      }
      else {
        this.charge_recursive(current_invoice_index + 1);
      }
    };
  }

  public onClickCharge(event: any): void {
    this.sent_invoice_codes = [];
    this.sent_success_invoice_codes = [];
    this.sent_fail_invoice_codes = [];
    this.charge_recursive(0);
  }

  public onFilterKeyUp(filterValue: string) {
    this.filter_text = filterValue;
    if (Utils.isValue(this.data_source)) {
      // this.data_source.filter = filterValue.trim().toLowerCase();
      this.applyFilter(filterValue);
    }
    // this.previousData = [...this.data_source.filteredData];
    this.previousData = this.filterSelectData();
    console.log('前データとして保存されたデータ:', this.previousData);

    // this.updateData = this.generateUpdateData(this.previousData);
    this.updateData = this.generateUpdateData(this.previousData, filterValue);
    console.log('更新後用のデータ:', this.updateData);

    const updateDataCount = this.updateData.length;
    console.log('選択された件数:', updateDataCount);
    this.updateDemandSendButtonDisabled();
  }


  public applyFilter(filterValue: string): void {
    const checkboxFilter =
    this.isCheckedOne && !this.isChecked
      ? 'unpaid_count_1'
      : this.isChecked && !this.isCheckedOne
      ? 'unpaid_count_2_or_more'
      : !this.isCheckedOne && !this.isChecked
      ? 'no_checked'
      : 'both_checked';

  const combinedFilter = `${checkboxFilter}|${this.filter_text || ''}`.trim();
    
    this.data_source.filterPredicate = (data: ChargeListItem, filter: string) => {
      const [checkboxCondition, searchText] = filter.split('|');
      console.log('Filter predicate: checkboxCondition:', checkboxCondition, 'searchText:', searchText);
      const matchesCheckbox = (() => {
        if (checkboxCondition === 'unpaid_count_1') return data.unpaid_count === 1;
        if (checkboxCondition === 'unpaid_count_2_or_more') return data.unpaid_count >= 2;
        if (checkboxCondition === 'both_checked') {
          return data.unpaid_count === 1 || data.unpaid_count >= 2;
        }
        if (checkboxCondition === 'no_checked') {
          return data.unpaid_count === 1 || data.unpaid_count >= 2 || data.unpaid_count === 0
        }
      })(); 
      const propertiesToCheck = [
        data.products_display_string,
        data.invoice_code,
        data.user_name,
        data.contract_code,
        data.some_other_property
      ];
    const matchesSearch = (() => {

      if (!searchText) {
        return true;
      }
      for (let prop of propertiesToCheck) {
        if (prop && typeof prop === 'string' && prop.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        }
      }
      if (data.products && Array.isArray(data.products)) {
        for (let product of data.products) {
          if (product.name && typeof product.name === 'string' && product.name.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    })();

    console.log('Generated combinedFilter:', combinedFilter);
    console.log('matchesCheckbox:', matchesCheckbox, 'matchesSearch:', matchesSearch);
    return matchesCheckbox && matchesSearch;
    };
    this.data_source.filter = combinedFilter;

    console.log('フィルター後のデータ:', this.filteredInvoices);
    this.previousData = [...this.data_source.filteredData];
    console.log('前データとして保存されたデータ:', this.previousData);
    this.updateData = this.applyFilterGenerateUpdateData(this.previousData);

    const updateDataCount = this.updateData.length;
    console.log('選択された件数:', updateDataCount);
  }

  private applyFilterGenerateUpdateData(filteredData: any[]): any[] {
    const updateData = filteredData
    .filter(item => item.is_selected === true)
    .map(item => {
      return {
        ...item,
      };
    });
    console.log('生成された更新データ:', updateData);
    return updateData;
  }

  private generateUpdateData(filteredData: any[], filterValue: string): any[] {
    let updatedData = filteredData
    .filter(item => item.is_selected === true)
    .map(item => {
      return {
        ...item,
      };
    });
    console.log('生成された更新データ:', updatedData);
    return updatedData;
  }

  private filterSelectData(): any[] {
    if (Utils.isValue(this.data_source)) {
      console.log('フィルターされたデータを取得:', this.data_source.filteredData);
      return [...this.data_source.filteredData];
    }
    return [];
  }


  public onChangePayStatus(event: any): void {
    console.log('ListChargesComponent::onChangePayStatus : ' + JSON.stringify(event));

    this.auth_service.apiSetInvoicePayStatus({ invoice_id: event.invoice.id, new_status: event.new_status }).subscribe(
      response => {
        if (Utils.isValue(response) && Utils.isValue(response.data)) {
          console.log(response);
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 2000;
          cfg.panelClass = ['notify_snackbar', 'success'];
          this.snackBar.open(
            response.message.message,
            'OK',
            cfg
          );
        }
      },
      error => {
        console.log(error);
        if (Utils.isValue(error.error.message) && Utils.isValue(error.error.message.message)) {
          this.snackBar.dismiss();
          const cfg = new MatSnackBarConfig();
          cfg.duration = 5000;
          cfg.panelClass = ['notify_snackbar', 'error'];
          this.snackBar.open(
            error.error.message.message,
            'OK',
            cfg
          );
        }
      }
    );
  }

  public onClickPutSpecialCase(invoice: any): void {
    const after_special_case = invoice.special_case ? false : true
    const params = { invoice_id: invoice.id, special_case: after_special_case }
    this.auth_service.putSpecialCase(params).subscribe(
      response => {
        invoice.special_case = response.data.special_case;
        console.log(`special_case: ${invoice.special_case}`);
      },
      error => {
        console.error('APIエラー:', error);
      }
    );
  }

  public onSendAllCheckChanged(event: any): void {
    console.log('ListChargesComponent::onSendAllCheckChanged : checked=' + this.select_send_all);
    this.data_source.data.forEach(invoice => (invoice.is_selected = this.select_send_all));
    this.select_send_all_indeterminate = false;
  }


  public onSendCheckChanged(event: any): void {
    console.log('ListChargesComponent::onSendCheckChanged : event=');
    console.log(event);
    // update all select checkbox checkstatus and indeterminate
    let checked_count = 0;
    let unchecked_count = 0;
    const filteredInvoicesSet = this.filteredInvoices.length > 0
      ? new Set(this.filteredInvoices.map(invoice => invoice.id)) : null;

    const inFiltered = filteredInvoicesSet ? this.invoices.filter(invoice => filteredInvoicesSet.has(invoice.id))
      : this.invoices;
    this.select_send_all_disabled = true;

    inFiltered.forEach(invoice => {
      if (invoice.is_selected) {
        checked_count++;
        console.log('選択されたユーザー:', invoice);
      } else {
        unchecked_count++;
      }
    });
    this.checked_count = checked_count;
    // this.selectedCount = checked_count;
    console.log('選択されているユーザー数:', this.checked_count);

    if (checked_count <= 0 && unchecked_count <= 0) {
      this.select_send_all = false;
      this.select_send_all_indeterminate = true;
    } else if (checked_count > 0 && unchecked_count > 0) {
      this.select_send_all = true;
      this.select_send_all_indeterminate = true;
    } else if (checked_count <= 0 && unchecked_count > 0) {
      this.select_send_all = false;
      this.select_send_all_indeterminate = false;
    } else if (checked_count > 0 && unchecked_count <= 0) {
      this.select_send_all = true;
      this.select_send_all_indeterminate = false;
    } else {
      this.select_send_all = false;
      this.select_send_all_indeterminate = false;
    }
  }

  public yen(num: number): string {
    return Utils.yen(num);
  }

  public datePart(datestring: string): string {
    return Utils.getDatePart(datestring, false);
  }

  private productString(product: ChargeProductItem): string {
    let product_string = product.title;
    if (Utils.isValue(product.device_code_1) && Utils.isValue(product.device_code_1.value)) {
      if (product.device_code_1.value.toLowerCase() !== 'undefined') {
        product_string = product_string + ' [' + product.device_code_1.value + ']';
      }
    }
    return product_string;
  }

  public productsString(products: Array<ChargeProductItem>): string {
    const product_titles = new Array<string>();
    products.forEach(product => {
      product_titles.push(this.productString(product));
    });
    // console.log('ListChargesComponent::productsString : products=' + JSON.stringify(product_titles));
    return Utils.joinStringsToBRLines(product_titles);
  }

  private updateDemandSendButtonDisabled() {
    this.demand_send_button_disabled = true;
    const demand_button = this._el.querySelector('#demand_send_button');
    if (!Utils.isValue(this.unpaid_only) || !Utils.isValue(this.invoice_month) || !Utils.isValue(this.selecting_pay_method)) {
      return;
    }
    if (!Utils.isValue(this.selecting_demand_level)) {
      return;
    }
    if (this.filter_text.length > 0) {
      return;
    }
    this.demand_send_button_disabled = false;
  }


  //中断確認
  public onClickConfirmation(): void {
    let selectedItems = [];
    const filterValue = this.filter_text;
    const filteredInvoicesSet = this.filteredInvoices.length > 0
      ? new Set(this.filteredInvoices.map(invoice => invoice.id)) : null
    if (filteredInvoicesSet) {
      const inFiltered = this.invoices.filter(invoice => filteredInvoicesSet.has(invoice.id))
      inFiltered.forEach(invoice => {
        if (invoice.is_selected) {
          selectedItems.push(invoice);
        }
      })
    } else {
      // selectedItems = this.data_source.data.filter(item => item.is_selected);
      selectedItems = this.generateUpdateData(this.previousData, filterValue)
    }
    console.log("selectedItems>>:", selectedItems);
    if (selectedItems.length === 0) {
      console.log('選択されたユーザーがいません。');
      alert('選択されたユーザーがいません。')
      return;
    }

    of(...selectedItems)
      .pipe(
        concatMap(item => {
          return new Observable(observer => {
            if (Utils.isValue(item.products) && item.products.length > 0) {
              console.log('item.products:', item.products);
              let trueCount = 0;

              of(...item.products)
                .pipe(
                  concatMap((product) => {
                    return new Observable(observer => {
                      console.log('telno:', product.telno);

                      if (!product.telno || product.telno.trim() === '') {
                        console.warn('telnoがnullまたはundefinedです。APIリクエストは送信されません。');
                        observer.complete();
                      } else {
                        const params = {
                          info_type: 'telno',
                          info: product.telno || '',
                        };
                        console.log('1秒後に送信するパラメータ:', params);
                        this.auth_service.apiLinePause(params).subscribe(
                          response => {
                            console.log(response);
                            if (response.data && response.data.line_pause === null) {
                              item.line_pause = null;
                            } else if (response.data && response.data.line_pause === true) {
                              trueCount++;
                            }
                            item.line_pause = trueCount;
                            console.log(`line_pauseがtrueの数: ${trueCount}`);
                            observer.complete();
                          },
                          error => {
                            console.error('APIエラー:', error);
                            observer.complete();
                          }
                        );
                      }
                    });
                  }),
                  delay(1000)
                )
                .subscribe({
                  complete: () => {
                    console.log('このユーザーのリクエストが完了しました');
                    observer.complete();
                  }
                });
            } else {
              item.line_pause = '-';
              observer.complete();
            }
          });
        })
      )
      .subscribe({
        complete: () => {
          console.log('すべてのユーザーのリクエストが完了しました');
          alert('すべてのユーザーのリクエストが完了しました。')
        }
      });
  }


}
