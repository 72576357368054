import {environment} from 'src/environments/environment';
export class Url {
    public static BASE = environment.apiRoot;
    public static SIGN_UP = Url.BASE + '/signup_user';
    public static LOGIN = Url.BASE + '/auth_user';
    public static LOGOUT = Url.BASE + '';
    public static PASSWORD_CHANGE = Url.BASE + '';

    // ユーザー(user)
    public static IS_EXIST_USER = Url.BASE + '/api/user/is_exist';
    public static IS_MEMBER_USER = Url.BASE + '/api/user/is_member';
    public static ADD_USER_PROPERTY = Url.BASE + '/api/user/property/add';
    public static UPDATE_USER_PROPERTY = Url.BASE + '/api/user/property/update';
    public static DELETE_USER_PROPERTY = Url.BASE + '/api/user/property/delete';

    // 契約(contract)
    public static ADD_CONTRACT = Url.BASE + '/api/contract/add';
    public static UPDATE_FIELD_CONTRACT = Url.BASE + '/api/contract/update_field';
    public static LIST_CONTRACTS = Url.BASE + '/api/contract/list';
    public static GET_CONTRACT = Url.BASE + '/api/contract/get';
    public static CHANGE_CONTRACT_OWNER =  Url.BASE + '/api/contract/change_contract_owner';
    public static NEW_CONTRACT_VIA_CHECKFRONT = Url.BASE + '/api/contract/new_contract_via_checkfront';
    public static ENUM_CONTRACT_STATUSES = Url.BASE + '/api/contract/enum_statuses';
    public static UPDATE_CONTRACT_STATUS = Url.BASE + '/api/contract/update_status';
    public static AGGRIGATION_DATA_OF_CONTRACT = Url.BASE + '/api/contract/aggregation_data_of_contract';
    public static MONTHLY_SUMMARY = Url.BASE + '/api/contract/monthly_summary';
    public static DOCUMENT_FILE = Url.BASE + '/api/contract/document_file';
    public static GET_LINE_STATUS = Url.BASE + '/api/contract/line_status/get';
    public static SET_LINE_STATUS = Url.BASE + '/api/contract/line_status/set';
    public static GET_REFERRAL_POINTS = Url.BASE + '/api/contract/referral_code_issues';
    public static SET_REFERRAL_POINTS = Url.BASE + '/api/contract/referral_code_issues';

    // 親権者同意書(parental_consents)
    public static PARENTAL_CONSENT = Url.BASE + '/api/parental_consent/list';    // + contract_id
    public static PARENTAL_CONSENT_UPDATE_FIELD = Url.BASE + '/api/parental_consent/update';    // + parental_consent_id
    public static PARENTAL_CONSENT_DOCUMENTS = Url.BASE + '/api/parental_consent_document/list';    // + parental_consent_id
    public static PARENTAL_CONSENT_DOCUMENTS_PREPARE = Url.BASE + '/api/parental_consent_document/prepare';    // + parental_consent_id
    
    // 契約商品(contract_product)
    public static SET_CONTRACT_PRODUCT_RETURN_INFORMATION = Url.BASE + '/api/contract_product/set_return_information';
    public static ENUM_CONTRACT_PRODUCT_STATUSES = Url.BASE + '/api/contract_product/enum_statuses';
    public static UPDATE_CONTRACT_PRODUCT_STATUS = Url.BASE + '/api/contract_product/update_status';
    public static CLOSE_LINES_AT_MONTH = Url.BASE + '/api/contract_product/close_lines_at_month';
    public static GET_LINE_STATUS_V2 = Url.BASE + '/api/contract_product/line_status';  // + contract_product_id
    public static POST_LINE_PAUSE_STATUS = Url.BASE + '/api/contract_product/pause_status';  // + contract_product_id
    public static POST_LINE_COUPON_STATUS = Url.BASE + '/api/contract_product/coupon_status';  // + contract_product_id
    public static POST_5G_STATUS = Url.BASE + '/api/contract_product/5g_status';  // + contract_product_id

    // 請求(invoice)
    public static GENERATE_INVOICE = Url.BASE + '/api/invoice/generate_at';
    public static GENERATE_INVOICES = Url.BASE + '/api/invoice/generate_in_month';
    public static HAS_INVOICE_WITH_MONTH = Url.BASE + '/api/invoice/has_at';
    public static HAS_INVOICES_WITH_MONTH = Url.BASE + '/api/invoice/has_in_month';
    public static LIST_INVOICES_IN_MONTH = Url.BASE + '/api/invoice/list_in_month';
    public static INVOICE_CONTENT = Url.BASE + '/api/invoice/get_content';
    public static LIST_UNASSIGNED_INVOICE_CONTENT_PROPERTIES = Url.BASE + '/api/invoice/list_unassigned_content_properties';
    public static ADD_INVOICE_CONTENT_PROPERTY = Url.BASE + '/api/invoice/add_content_property';
    public static SET_INVOICE_CONTENT_PROPERTY = Url.BASE + '/api/invoice/set_content_property';
    public static SET_INVOICE_SEND_STATUS = Url.BASE + '/api/invoice/set_send_status';
    public static SET_INVOICE_PAY_STATUS = Url.BASE + '/api/invoice/set_pay_status';
    public static SET_INVOICE_PAY_METHOD = Url.BASE + '/api/invoice/set_pay_method';
    public static CHARGE_UNPAID_INVOICE = Url.BASE + '/api/invoice/charge_unpaid';
    public static SEND_INVOICE = Url.BASE + '/api/invoice/send_invoice';
    public static LIST_CHARGES = Url.BASE + '/api/invoice/charges';
    public static SEND_DEMAND_LETTER = Url.BASE + '/api/invoice/send_demand_letter';
    public static GET_LINE_PAUSE = Url.BASE + '/api/invoice/get_line_pause';
    public static PUT_LINE_PAUSE = Url.BASE + '/api/invoice/put_line_pause';
    public static PUT_SPECIAL_CASE = Url.BASE + '/api/invoice/special_case'

    // システムタスク
    public static LIST_SYSTEM_TASK = Url.BASE + '/api/system_task/list';
    public static LIST_SYSTEM_TASK_ERROR = Url.BASE + '/api/system_task/error_list';

    // 管理用ツール(adminロール専用)
    public static ADMIN_DELETE_CONTRACT = Url.BASE + '/api/admin/tool/delete_contract';
    public static ADMIN_ADD_CONTRACT_PROPERTY_TYPE = Url.BASE + '/api/admin/tool/add_contract_property_type';
    public static ADMIN_ADD_PRODUCT_TYPE = Url.BASE + '/api/admin/tool/add_product_type';
    public static ADMIN_ADD_PRODUCT_PROPERTY_TYPE = Url.BASE + '/api/admin/tool/add_product_property_type';
    public static ADMIN_APPLY_PRODUCT_PROPERTY_TYPE_TO_PRODUCT = Url.BASE + '/api/admin/tool/apply_product_property_type_to_product';
    public static ADMIN_CHECKFRONT_API_TEST = Url.BASE + '/api/admin/tool/checkfront_api_test';

    // 編集一般
    public static SET_CONTRACT_PRODUCT_START_AT = Url.BASE + '/api/set_contract_product_start_at';
    public static SET_CONTRACT_PRODUCT_END_AT = Url.BASE + '/api/set_contract_product_end_at';
    public static SET_CONTRACT_PRODUCT_PROPERTY = Url.BASE + '/api/set_contract_product_property';

    // 契約関連
    public static ADD_CONTRACT_PRODUCT = Url.BASE + '/api/add_contract_product';
    public static REMOVE_CONTRACT_PRODUCT = Url.BASE + '/api/remove_contract_product';
    public static LIST_UNASSIGNED_USER_PROPERTIES = Url.BASE + '/api/list_unassigned_user_properties';
    public static LIST_UNASSIGNED_CONTRACT_PROPERTIES = Url.BASE + '/api/list_unassigned_contract_properties';
    public static ADD_CONTRACT_PROPERTY = Url.BASE + '/api/add_contract_property';
    public static REMOVE_CONTRACT_PROPERTY = Url.BASE + '/api/remove_contract_property';
    public static LIST_UNASSIGNED_CONTRACT_PRODUCT_PROPERTIES = Url.BASE + '/api/list_unassigned_contract_product_properties';
    public static ADD_CONTRACT_PRODUCT_PROPERTY = Url.BASE + '/api/add_contract_product_property';
    public static REMOVE_CONTRACT_PRODUCT_PROPERTY = Url.BASE + '/api/remove_contract_product_property';
    public static LIST_CONTRACT_PAY_HISTORIES = Url.BASE + '/api/list_contract_pay_histories';
    public static SEND_SHIPPING_NOTIFY = Url.BASE + '/api/contract/send_notify_shipping';
    public static SAGAWA_LABEL_DATA = Url.BASE + '/api/contract/sagawa_label_data';
    public static EHIDEN3_LABEL_DATA = Url.BASE + '/api/contract/sagawa_label_data_3';
    public static AIRPORT_LABEL_DATA = Url.BASE + '/api/contract/airport_label_data';

    // 契約メモ
    public static ADD_CONTRACT_MEMO = Url.BASE + '/api/contract_memo/add';
    public static LIST_CONTRACT_MEMOS = Url.BASE + '/api/contract_memo/list';
    public static DELETE_CONTRACT_MEMO = Url.BASE + '/api/contract_memo/delete';

    // テキスト検索
    public static SEARCH_TEXT_FAST = Url.BASE + '/api/search_text_fast';
    public static SEARCH_TEXT = Url.BASE + '/api/search_text';
    public static SEARCH_TEXT_IN_USERS = Url.BASE + '/api/search_text_in_users';
    public static SEARCH_TEXT_IN_CONTRACTS = Url.BASE + '/api/search_text_in_contracts';

    // ファイルエクスポート
    public static REQUEST_CHARGE_INVOICE_LIST = Url.BASE + '/api/request_charge_invoice_list';
    public static REQUEST_INVOICE_POINTS_LIST = Url.BASE + '/api/request_invoice_points_list';
    public static REQUEST_REFERRAL_POINTS_LIST = Url.BASE + '/api/request_referral_points_list';
    public static REQUEST_MONTHLY_LIST_BASE = Url.BASE + '/api/admin/monthly';

    // ファイルインポート関連
    public static IMPORT_CHARGE_RESULT_CREDIT = Url.BASE + '/file_imports/regist_charge_result_credit';
    public static IMPORT_CHARGE_RESULT_SMARTPIT = Url.BASE + '/file_imports/regist_charge_result_smartpit';
    public static IMPORT_CHARGE_RESULT_GI_BILL = Url.BASE + '/file_imports/regist_charge_result_gi_bill';
    public static IMPORT_KPD_CHARGE_DATA = Url.BASE + '/file_import/api_import_kpd_charge';

    // 出荷作業関連
    public static LIST_SHIPPING_CONTRACTS = Url.BASE + '/api/list_shipping_contracts';
    public static UPDATE_CONTRACT_PROPERTY = Url.BASE + '/api/update_contract_property';
    public static UPDATE_CONTRACT_PRODUCT_PROPERTY = Url.BASE + '/api/update_contract_product_property';
    public static ASSIGN_SIM_ID = Url.BASE + '/api/assign_sim_id';
    public static ACTIVATE_SIM = Url.BASE + '/api/ship/activate_sim';


    public static CREATE_PRODUCT_TYPE = Url.BASE + '/product_types/add';
    public static PRODUCT_TYPES_LIST = Url.BASE + '/product_types/list';
    public static DELETE_PRODUCT_TYPE = Url.BASE + '/product_types/delete';
    public static PRODUCT_PROPERTIES_IN_PRODUCT = Url.BASE + '/product_types/get_list_property_and_types_in';
    public static CREATE_PRODUCT_PROPERTY = Url.BASE + '/product_properties/add_in_product_type_by_key';
    public static DELETE_PRODUCT_PROPERTY = Url.BASE + '/product_properties/delete';
    public static SELECTABLES_PRODUCT_PROPERTY_TYPES = Url.BASE + '/product_property_types/selectables';

    // 商品
    public static LIST_PRODUCTS = Url.BASE + '/api/list_products';

    // <<< 固定回線関連
    public static GET_FIBER_SCHEDULES = Url.BASE + '/api/fiber_schedules';          // :contract_product_id 
    public static GET_FIBER_SCHEDULE = Url.BASE + '/api/fiber_schedule/properties';          // :contract_product_id
    public static GET_FIBER_SCHEDULE_PROPERTY = Url.BASE + '/api/fiber_schedule/property'; // :contract_product_id/:property_key
    public static PUT_FIBER_SCHEDULE_PROPERTY = Url.BASE + '/api/fiber_schedule/property';
    public static POST_FIBER_GMO_REGISTRATE_MEMBER_ID = Url.BASE + '/api/fiber_schedule/gmo_registrate_member_id';
    public static POST_FIBER_CHARGE_CREDIT = Url.BASE + '/api/fiber_schedule/charge_credit';
    public static POST_FIBER_UPDATE_FIXED_SCHEDULE = Url.BASE + '/api/fiber_schedule/update_fixed_schedule';
    public static POST_FIBER_CHARGED_SMARTPIT = Url.BASE + '/api/fiber_schedule/charged_smartpit';
    public static POST_FIBER_EXPORT_YAMATO_CSV = Url.BASE + '/api/fiber_schedules/exported_yamato_csv';
    public static POST_FIBER_IMPORT_YAMATO_CSV = Url.BASE + '/api/fiber_schedules/import_yamato_csv';

    public static GET_CONTRACT_PRODUCT_PROPERTY = Url.BASE + '/api/contract_product/properties'; // :contract_product_id/:property_key
    public static PUT_CONTRACT_PRODUCT_PROPERTY = Url.BASE + '/api/contract_product/property';

    public static GET_CONTRACT_PROPERTY = Url.BASE + '/api/contract/properties'; // :contract_id/:property_key
    public static PUT_CONTRACT_PROPERTY = Url.BASE + '/api/contract/property';

    public static GET_USER_PROPERTY = Url.BASE + '/api/user/properties'; // :contract_id/:property_key
    public static PUT_USER_PROPERTY = Url.BASE + '/api/user/property';

    public static POST_FIBER_SHIPPING_NOTIFICATION = Url.BASE + '/api/fiber_schedules/shipping_notification';
    public static POST_FIBER_INST_REMIND_NOTIFICATION = Url.BASE + '/api/fiber_schedules/inst_remind_notification';
    public static POST_FIBER_LINE_OPEN_NOTIFICATION = Url.BASE + '/api/fiber_schedules/line_open_notification';
    public static POST_CHANGE_FIBER_TYPE = Url.BASE + '/api/contract_product/change_fiber_type';
    // >>> 固定回線関連

    // TOPUP CAMPAIGNS
    public static GET_TOP_UP_CAMPAIGNS = Url.BASE + '/api/top_up_ticket/campaigns';
    public static GET_TOP_UP_TICKETS = Url.BASE + '/api/top_up_ticket/tickets';
    public static POST_GENERATE_TOP_UP_TICKETS = Url.BASE + '/api/top_up_ticket/generate_tickets';

    // no implementation yet
    public static USERS_LIST = Url.BASE + '/users/list';
    public static USER_PROPERTIES_LIST = Url.BASE + '/user_properties/list';
    public static CONTRACTS_LIST = Url.BASE + '/contracts/list';

    // esim
    public static POST_PUBLISH_ESIM_LINK = Url.BASE + '/api/esims/publish_esim_link';
    public static POST_REISSUE_ESIM = Url.BASE + '/api/esims/reissue';
}
