import { Component, OnInit, AfterViewChecked } from '@angular/core';
import {
  MatDialog,
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material';
import { Utils } from '../../../helpers';
import { ElementRef } from '@angular/core';
import { FlashMessageService } from '../../../services/misc';
import { AuthService } from '../../../services/auth';
import {
  AuthGetReply,
  ContractContent,
  UserProperty,
  ContractProperty,
  ContractProduct,
  ContractProductProperty,
  ParentalConsent
} from '../../../models';
import { isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';
import { environment } from 'src/environments/environment';
import { Candidates } from 'src/app/constants';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-fiber-schedule',
  templateUrl: './fiber-schedule.component.html',
  styleUrls: ['./fiber-schedule.component.scss']
})
export class FiberScheduleComponent implements OnInit, AfterViewChecked {

  public fiberScheduleStatusCandidates: Array<string>;
  public apiRoot: string;
  public contract_id: number;
  public contract_product_id: number;
  public data: any;
  public fiber_order_root = environment.fiberOrderRoot;
  public confirm_dialog_ref: any;

  constructor(
    protected el: ElementRef,
    protected flash_service: FlashMessageService,
    protected auth_service: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
    this.data = null;
    this.apiRoot = environment.apiRoot;
    this.fiberScheduleStatusCandidates = ['---'];
    Candidates.fiber_statuses.forEach(el => {
      this.fiberScheduleStatusCandidates.push(el);
    });
    console.log('candidates : ', this.fiberScheduleStatusCandidates);
  }

  ngOnInit() {
    this.contract_product_id = Number.parseInt(Utils.getUrlParameter('contract_product'));
    if (Utils.isValue(this.contract_product_id)) {
      this.refresh();
    }
  }

  public refresh() {
    const params = {
      contract_product_id: this.contract_product_id
    };

    if (this.auth_service.isLoggingIn() !== true) {
      this.auth_service.login_after_url = window.location.href;
      location.href = '/auth/login';
      return;
    }

    this.auth_service.apiIsMemberUser('operator', this, function (
      reply: AuthGetReply,
      context: any
    ) {
      if (reply.data.is_member === true) {
      } else {
        this.auth_service.login_after_url = window.location.href;
        location.href = '/auth/login';
      }
    });

    this.auth_service.apiGetFiberSchedule(this.contract_product_id).subscribe(
      response => {
        this.data = response.data;
        console.log(`Initialize : `, this.data);
      },
      error => {
        // target.classList.add('error');
        if (!error.error || !error.error.message) {
          if (error.message) {
            this.snackBarError(error.message, 'OK');
          }
          else {
            this.snackBarError("Unhandled error", 'OK');
          }
        }
        else {
          this.snackBarError(error.error.message.message, 'OK');
        }
      }
    );
  }

  ngAfterViewChecked() {
    document.title = `Schedule:${this.data && this.data.contract.contract_code}`;
    this.updateFiberSchedulePaymentSumFee();
  }

  public getUserProperty(key: string): any {
    if (!this.data || !this.data.user_properties) {
      return null;
    }

    const founds = this.data.user_properties.filter(
      item => {
        if (item.key === key) {
          return item;
        }
      }
    )
    return founds[0];
  }

  public getDocumentUploadLink(): string {
    if (this.data && this.data.contract) {
      return `https://my.sakuramobile.jp/document_files/v2add/${this.data.contract.contract_hash}`;
    }
    return null;
  }

  public getScheduleOptionFormLink(): string {
    if (this.data && this.data.contract) {
      return `${this.fiber_order_root}/installation-option-form?contract_hash=${this.data.contract.contract_hash}`;
    }
    return null;
  }


  public getScheduleInputFormLink(): string {
    if (this.data && this.data.contract) {
      return `${this.fiber_order_root}/installation-schedule-form?contract_hash=${this.data.contract.contract_hash}`;
    }
    return null;
  }

  public getUserPropertyString(key: string, upcase: boolean = null): string {
    const item = this.getUserProperty(key);
    if (!item) {
      return null;
    }
    let result = item.value
    if (upcase === true) {
      result = result.toUpperCase();
    }
    else if (upcase === false) {
      result = result.toLowerCase();
    }
    return result;
  }

  public getContractProperty(key: string): any {
    if (!this.data || !this.data.contract_properties) {
      return null;
    }

    const founds = this.data.contract_properties.filter(
      item => {
        if (item.key === key) {
          return item;
        }
      }
    )
    return founds[0];
  }

  public hasGMO(): boolean | null {
    if (this.data === null || this.data === undefined) {
      return null;
    }
    if (typeof this.data.is_gmo_user === 'boolean') {
      return this.data.is_gmo_user;
    }
  }

  public postGMORegistrateMemberID(): any {
    const param: {[key: string]: string} = {
      member_id: this.getUserPropertyString("GMO USER ID"),
      member_name: this.getUserPropertyString("USER FULL NAME")
    }
    this.confirm_dialog_ref = this.dialog.open(ConfirmDialogComponent, {
      width: '50rem',
      hasBackdrop: true,
      data: {
        title: 'GMO会員登録',
        message:
          'この内容で会員登録します。よろしいですか？<br/>' +
          `会員ID:  ${this.getUserPropertyString("GMO USER ID")}<br/>` +
          `会員名:  ${this.getUserPropertyString("USER FULL NAME")}`
      }
    });
    this.confirm_dialog_ref.afterClosed().subscribe(result => {
      console.log(result);
      if (Utils.isValue(result)) {
        if (result.closeby === 'ok') {
          this.auth_service.apiPostFiberGMORegistrateMemberID(param).subscribe(
            response => {
              console.log('postGMORegistrateMemberID:', response);
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'success'];
              this.snackBar.open('Success', 'OK', cfg);
              this.refresh();
            },
            error => {
              console.log('postGMORegistrateMemberID error:', error.error.message);
              const cfg = new MatSnackBarConfig();
              cfg.duration = 5000;
              cfg.panelClass = ['notify_snackbar', 'error'];
              this.snackBar.open('500 Internal Server Error', 'OK', cfg);
            }
          );
        }
      }
    });
  }

  public getContractPropertyString(key: string, upcase: boolean = null): string {
    const item = this.getContractProperty(key);
    if (!item) {
      return null;
    }
    let result = item.value
    if (upcase === true) {
      result = result.toUpperCase();
    }
    else if (upcase === false) {
      result = result.toLowerCase();
    }
    return result;
  }


  public getContractProductProperty(key: string): any {
    if (!this.data || !this.data.contract_product_properties) {
      return null;
    }

    const found = this.data.contract_product_properties.find(
      item => {
        if (item.key === key) {
          return item;
        }
      }
    )
    return found;
  }

  public udpateContractProductPropertyLocal(key: string, value: string): void {
    if (!this.data || !this.data.contract_product_properties) {
      return null;
    }

    const found = this.data.contract_product_properties.forEach(el => {
      if (el.key === key) {
        el.value = value;
      }
    });
  }

  public getContractProductPropertyString(key: string, upcase: boolean = null): string {
    const item = this.getContractProductProperty(key);
    if (!item) {
      return null;
    }
    let result = item.value
    if (result && result.length > 0) {
      if (upcase === true) {
        result = result.toUpperCase();
      }
      else if (upcase === false) {
        result = result.toLowerCase();
      }
    }
    return result;
  }

  public setContractProductPropertyString(key: string, event: any): void {
    this.putFiberScheduleValueChange(key, event.srcElement.value, event.srcElement);
  }

  public getContractProductPropertyBool(key: string, t: string, f: string): string {
    const item = this.getContractProductProperty(key);
    if (!item) {
      return null;
    }
    if (item.value === true || item.value === "true" || item.value === "t" || item.value === 1) {
      return t;
    }
    return f;
  }

  public getContractProductPropertyDate(key: string): Date {
    const item = this.getContractProductProperty(key);
    if (!item) {
      return null;
    }
    return new Date(item.value);
  }

  public getContractProductPropertyJson(key: string): any {
    const item = this.getContractProductProperty(key);
    if (!item) {
      return null;
    }
    return JSON.parse(item.value);
  }

  public putContractProductProperty(key: string, value: string, target: any): void {
    const _target = target;
    const org_property = this.getContractProductProperty(key);
    if (!org_property) {
      console.log('ERROR : Not found the contract product property having the specified key. key=', key);
      return;
    }
    this.udpateContractProductPropertyLocal(key, value); // update local value
    this.auth_service.apiPutContractProductProperty({
      contract_product_id: this.data.contract_product.id,
      key: key,
      value: value
    }).subscribe(
      response => {
        _target.classList.remove('error');
        this.snackBarSuccess("Update succeeded", 'OK');
      },
      error => {
        _target.classList.add('error');
        if (!error.error || !error.error.message) {
          if (error.message) {
            this.snackBarError(error.message, 'OK');
          }
          else {
            this.snackBarError("Unhandled error", 'OK');
          }
        }
        else {
          this.snackBarError(error.error.message.message, 'OK');
        }
      }
    );
  }

  public getFiberScheduleString(field: string): string {
    if (!this.data || !this.data.fiber_schedule || !this.data.fiber_schedule[field]) {
      return null;
    }
    return this.data.fiber_schedule[field].toString();
  }

  public putFiberScheduleValueChange(key: string, value: string, target: any): void {
    this.data.fiber_schedule[key] = value;
    const param = {
      contract_product_id: this.data.contract_product.id,
      key: key,
      value: value
    };
    this.auth_service.apiPutFiberScheduleProperty(param).subscribe(
      response => {
        target.classList.remove('error');
        this.snackBarSuccess("Update succeeded", 'OK');
      },
      error => {
        target.classList.add('error');
        if (!error.error || !error.error.message) {
          if (error.message) {
            this.snackBarError(error.message, 'OK');
          }
          else {
            this.snackBarError("Unhandled error", 'OK');
          }
        }
        else {
          this.snackBarError(error.error.message.message, 'OK');
        }
      }
    )
  }

  public setFiberScheduleSelectOption(field: string, event: any) {
    if (!event || !event.srcElement) {
      return;
    }
    this.putFiberScheduleValueChange(field, event.srcElement.value, event.srcElement);
    if (field.includes('_fee_')) {
      this.updateFiberSchedulePaymentSumFee();
    }
  }

  public setFiberScheduleBool(field: string, event: any, t: string, f: string) {
    let value = false;
    if (event.srcElement.value === t) {
      value = true;
    }
    else if (event.srcElement.value === f) {
      value = false;
    }
    this.putFiberScheduleValueChange(field, value.toString(), event.srcElement);
  }

  public setFiberScheduleBoolForISPAndNotification(field: string, event: any, t: string, f: string) {
    let value = false;
    if (event.srcElement.value === t) {
      value = true;
    }
    else if (event.srcElement.value === f) {
      value = false;
    }
    this.putFiberScheduleValueChange(field, value.toString(), event.srcElement);
    if (this.getFiberScheduleBool('isp_registration', '済', '未') === '済' &&
      this.getFiberScheduleBool('inst_reminder_notified', '済', '未') === '済') {
      const select_els = document.getElementsByName('fiber_schedule_status_selected');
      if (select_els.length > 0) {
        const select_el = <HTMLSelectElement>select_els[0];
        select_el.value = "事前連絡とISP登録済み";
        this.putFiberScheduleValueChange('fiber_schedule_status', select_el.value, select_el);
      }
    }
  }

  public get deadlineDateAtDate(): Date {
    const value = this.getFiberScheduleString('confirmation_deadline_at');
    if (!value) {
      return null;
    }
    return new Date(value);
  }

  public get deadlineDateAtTime(): String {
    const value = this.getFiberScheduleString('confirmation_deadline_at');
    if (!value) {
      return null;
    }
    const dt = new Date(value);
    let result = '10AM';
    if (dt.getHours() == 10) {
      result = '10AM';
    }
    else if (dt.getHours() == 12) {
      result = '12PM';
    }
    else if (dt.getHours() == 15) {
      result = '3PM';
    }
    else if (dt.getHours() == 17) {
      result = '5PM';
    }
    else if (dt.getHours() == 19) {
      result = '7PM';
    }
    return result;
  }

  public setDeadlineDateAtDate(event: any): void {
    console.log('setDeadlineDateAtDate', event);
    if (!event.value) {
      event.targetElement.classList.add("error")
      this.snackBarError("Invalid date", "OK");
      return;
    }

    let new_dt = event.value;
    if (this.data.fiber_schedule && this.data.fiber_schedule.confirmation_deadline_at) {
      let dest_dt = new Date(this.data.fiber_schedule.confirmation_deadline_at);
      new_dt.setHours(dest_dt.getHours());
    }
    else {
      new_dt.setHours(10);
    }

    let new_date = Utils.dateToJSTString(new_dt);

    if (!new_date) {
      event.targetElement.classList.add("error")
      this.snackBarError("Invalid date", "OK");
      return;
    }

    this.putFiberScheduleValueChange(`confirmation_deadline_at`, new_date, event.targetElement);
  }

  public setDeadlineDateAtTime(event: any): void {
    console.log('setDeadlineDateAtTime', event);
    let new_dt = new Date(this.data.fiber_schedule.confirmation_deadline_at);
    if (event.srcElement.value === '10AM') {
      new_dt.setHours(10);
    }
    else if (event.srcElement.value === '12PM') {
      new_dt.setHours(12);
    }
    else if (event.srcElement.value === '3PM') {
      new_dt.setHours(15);
    }
    else if (event.srcElement.value === '5PM') {
      new_dt.setHours(17);
    }
    else if (event.srcElement.value === '7PM') {
      new_dt.setHours(19);
    }
    else {
      new_dt.setHours(10);
    }

    let new_date = Utils.dateToJSTString(new_dt);

    if (!new_date) {
      event.targetElement.classList.add("error")
      this.snackBarError("Invalid date", "OK");
      return;
    }

    this.putFiberScheduleValueChange(`confirmation_deadline_at`, new_date, event.srcElement);
  }

  // 'confirmation_deadline_at'

  public setNttAddress(event: any): void {
    console.log(event.srcElement.value);
    const lines = event.srcElement.value.split('\n');
    let formatError = false;
    if (lines.length < 2 || lines.length > 4) {
      formatError = true;
    }
    lines.forEach(line => {
      if (line.length > 32) {
        formatError = true;
      }
    });
    if (formatError) {
      event.srcElement.classList.add('error');
      this.snackBarError('NTT address Format error', 'OK');
      return;
    }
    this.putFiberScheduleValueChange('ntt_system_inst_address', event.srcElement.value, event.srcElement);
  }

  public setFiberScheduleText(field: string, event: any) {
    this.putFiberScheduleValueChange(field, event.srcElement.value, event.srcElement);
  }

  public setFiberScheduleString(field: string, event: any): void {
    console.log(`setFiberScheduleString`, event);
    this.putFiberScheduleValueChange(field, event.srcElement.value, event.srcElement);
  }

  public getFiberScheduleBool(field: string, t: string, f: string): string {
    const value = this.getFiberScheduleString(field);
    if (!value) {
      return f;
    }
    if (value === t || value === "true" || value === "1" || value === "t") {
      return t;
    }
    return f;
  }

  public getFiberScheduleJson(field: string): any {
    const value = this.getFiberScheduleString(field);
    if (!value) {
      return null;
    }
    return JSON.parse(value);
  }

  public setFiberScheduleDtDate(field: string, event: any): void {
    if (!event.value) {
      return;
    }
    let current_dt = this.getFiberScheduleJson(field);
    if (!current_dt) {
      current_dt = {
        date: null,
        time: null
      }
    }
    current_dt.date = Utils.dateToDateString(event.value);
    this.putFiberScheduleValueChange(field, JSON.stringify(current_dt), event.targetElement);
  }

  public setFiberScheduleDtTime(field: string, event: any): void {
    console.log(`setFiberScheduleDtDate - field : ${field} : event : `, event);
    if (!event.srcElement.value) {
      return;
    }
    let current_dt = this.getFiberScheduleJson(field);
    if (!current_dt) {
      current_dt = {
        date: null,
        time: null
      }
    }
    current_dt.time = event.srcElement.value;
    this.putFiberScheduleValueChange(field, JSON.stringify(current_dt), event.srcElement);
  }

  public clearFiberScheduleDt(field: string, event: any): void {
    console.log(`clearFiberScheduleDtTime - field : ${field} : event : `, event);
    let current_dt = this.getFiberScheduleJson(field);
    if (!current_dt) {
      current_dt = {
        date: null,
        time: null
      }
    }
    current_dt.date = null;
    current_dt.time = null;
    this.putFiberScheduleValueChange(field, JSON.stringify(current_dt), event.srcElement);
  }

  public setFiberScheduleDate(field: string, event: any): void {
    if (!event.value) {
      event.targetElement.classList.add("error")
      this.snackBarError("Invalid date", "OK");
      return;
    }
    const new_date = Utils.dateToDateString(event.value);
    if (!new_date) {
      event.targetElement.classList.add("error")
      this.snackBarError("Invalid date", "OK");
      return;
    }
    this.putFiberScheduleValueChange(field, new_date, event.targetElement);
  }

  public get supportDateAtDate(): Date {
    const value = this.getFiberScheduleString('support_date_at');
    if (!value) {
      return null;
    }
    return new Date(value);
  }

  public getFiberLocation(latlng: string): string {
    const value = this.getContractProductPropertyJson('FIBER GOOGLE MAP INFO');
    if (!value) {
      return null;
    }
    if (latlng === 'lat') {
      return value.lat;
    }
    if (latlng === 'lng') {
      return value.lng;
    }
    return null;
  }

  public getDesiredInstallationDateTime(order: string): any {
    const value = this.getContractProductPropertyJson('FIBER DESIRED INSTALLATION DATE');
    if (!value || !value[order]) {
      return { order: order, dt: { date: null, time: null } };
    }
    // return value[order];
    if (order === 'first' && value.first) {
      return { order: 'first', dt: value.first }
    }
    if (order === 'second' && value.second) {
      return { order: 'second', dt: value.second }
    }
    if (order === 'third' && value.third) {
      return { order: 'third', dt: value.third }
    }
    return { order: order, dt: { date: null, time: null } };
  }

  public updateContractProductProperty(key: string, event: any): void {
    this.udpateContractProductPropertyLocal(key, event.srcElement.value);
    this.putContractProductProperty(key, event.srcElement.value, event.srcElement);
  }

  public updateContractProductPropertyBool(key: string, t: string, f: string, event: any): void {
    let value = false;
    if (event.srcElement.value === t) {
      value = true;
    }
    else if (event.srcElement.value === f) {
      value = false;
    }
    this.udpateContractProductPropertyLocal(key, value.toString());
    this.putContractProductProperty(key, value.toString(), event.srcElement);
  }

  public updateContractProductPropertyDate(key: string, event: any): void {
    const value = Utils.getDisplayDateString(event.value);
    this.udpateContractProductPropertyLocal(key, value);
    this.putContractProductProperty(key, value, event.targetElement);
  }

  public setDesiredInstallationDateTimeDate(order: string, event: any): void {
    const dt_value = new Date(event.value);
    const dt_value_string = Utils.getDisplayDateString(dt_value.toUTCString());
    const value = this.getContractProductPropertyJson('FIBER DESIRED INSTALLATION DATE');
    value[order].date = dt_value_string;
    this.putContractProductProperty('FIBER DESIRED INSTALLATION DATE', JSON.stringify(value), event.targetElement);
  }

  public setDesiredInstallationDateTimeTime(order: string, event: any): void {
    const value = this.getContractProductPropertyJson('FIBER DESIRED INSTALLATION DATE');
    console.log("event=", event);
    value[order].time = event.srcElement.value;
    this.putContractProductProperty('FIBER DESIRED INSTALLATION DATE', JSON.stringify(value), event.srcElement);
  }

  public getConvinientDayOfTheWeek(): Array<any> {
    const result = [];
    const value = this.getContractProductPropertyJson('FIBER CONTACT SCHEDULE');
    if (!value) {
      return result;
    }
    if (value.monday) {
      result.push({ order: 'Monday', value: value.monday });
    }
    if (value.tuesday) {
      result.push({ order: 'Tuesday', value: value.tuesday });
    }
    if (value.wednesday) {
      result.push({ order: 'Wednesday', value: value.wednesday });
    }
    if (value.thursday) {
      result.push({ order: 'Thursday', value: value.thursday });
    }
    if (value.friday) {
      result.push({ order: 'Friday', value: value.friday });
    }
    if (value.saturday) {
      result.push({ order: 'Saturday', value: value.saturday });
    }
    if (value.sunday) {
      result.push({ order: 'Sunday', value: value.sunday });
    }
    return result;
  }

  public setAConvinientDayOfTheWeek(day: string, event: any): void {
    const value = this.getContractProductPropertyJson('FIBER CONTACT SCHEDULE');
    console.log("event=", event);
    value[day.toLowerCase()] = event.srcElement.value;
    this.putContractProductProperty('FIBER CONTACT SCHEDULE', JSON.stringify(value), event.srcElement);
  }

  public toFilename(src): String {
    if (typeof src === "string") {
      return src;
    }
    return JSON.stringify(src);
  }

  public getDocuments(): Array<string> {
    console.log('Documents : ', );
    const documents = [];
    if (!this.data || !this.data.contract_properties || !this.data.contract_product_properties) {
      return documents;
    }

    // DOC FILE X
    const doc_file_ns = this.data.contract_properties.filter(
      el => {
        if (el.key.startsWith('DOC FILE ') && el.value !== null) {
          console.log('Documents(1)-A : ', el.value);
          return el.value;
        }
      }
    )
    doc_file_ns.forEach(el => {
      if (el) {
        console.log('Documents(1)-B : ', el);
        documents.push(el.value);
      }
    });

    // DOC FILES
    const doc_files = this.data.contract_properties.filter(
      el => {
        if (el.key === 'DOC FILES' && el.value !== null) {
          console.log('Documents(2)-A : ', el.value);
          return el.value;
        }
      }
    )
    if (doc_files[0] && doc_files[0].value) {
      const els = JSON.parse(doc_files[0].value);
      els.forEach(el => {
        console.log('Documents(2)-B : ', el);
        documents.push(el.file_name);
      })
    }
    return documents;
  }

  public dateAndTimeRangeAsDate(data: any): Date {
    if (!data || !data.date) {
      return null;
    }
    const dt = new Date(data.date);
    return dt;
  }

  public dateAndTimeRangeAsTimeRange(data: any): string {
    if (!data || !data.time) {
      return null;
    }
    return data.time;
  }

  public get preliminaryInvestigationDt(): any {
    const dt_obj = this.getFiberScheduleJson('preliminary_investigation_dt');
    if (!dt_obj) {
      return null;
    }
    return {
      date: this.dateAndTimeRangeAsDate(dt_obj),
      time: this.dateAndTimeRangeAsTimeRange(dt_obj)
    }
  }

  public get preliminaryInvestigationDate(): Date {
    const dt = this.preliminaryInvestigationDt;
    if (!dt) {
      return null;
    }
    return dt.date;
  }

  public get preliminaryInvestigationTime(): string {
    const dt = this.preliminaryInvestigationDt;
    if (!dt) {
      return null;
    }
    return dt.time;
  }


  public get preliminaryInvestigationFixedDt(): any {
    const dt_obj = this.getFiberScheduleJson('preliminary_investigation_dt_fixed');
    if (!dt_obj) {
      return null;
    }
    return {
      date: this.dateAndTimeRangeAsDate(dt_obj),
      time: this.dateAndTimeRangeAsTimeRange(dt_obj)
    }
  }

  public get preliminaryInvestigationFixedDate(): Date {
    const dt = this.preliminaryInvestigationFixedDt;
    if (!dt) {
      return null;
    }
    return dt.date;
  }

  public get preliminaryInvestigationFixedTime(): string {
    const dt = this.preliminaryInvestigationFixedDt;
    if (!dt) {
      return null;
    }
    return dt.time;
  }

  public get installDtDt(): any {
    const dt_obj = this.getFiberScheduleJson('install_dt');
    if (!dt_obj) {
      return null;
    }
    return {
      date: this.dateAndTimeRangeAsDate(dt_obj),
      time: this.dateAndTimeRangeAsTimeRange(dt_obj)
    }
  }

  public get installDtDate(): Date {
    const dt = this.installDtDt;
    if (!dt) {
      return null;
    }
    return dt.date;
  }

  public get installDtTime(): string {
    const dt = this.installDtDt;
    if (!dt) {
      return null;
    }
    return dt.time;
  }

  public get installDtFixedDt(): any {
    const dt_obj = this.getFiberScheduleJson('install_dt_fixed');
    if (!dt_obj) {
      return null;
    }
    return {
      date: this.dateAndTimeRangeAsDate(dt_obj),
      time: this.dateAndTimeRangeAsTimeRange(dt_obj)
    }
  }

  public get installDtFixedDate(): Date {
    const dt = this.installDtFixedDt;
    if (!dt) {
      return null;
    }
    return dt.date;
  }

  public get installDtFixedTime(): string {
    const dt = this.installDtFixedDt;
    if (!dt) {
      return null;
    }
    return dt.time;
  }


  public get deviceDeliveryDtDt(): any {
    const dt_obj = this.getFiberScheduleJson('device_delivery_dt');
    if (!dt_obj) {
      return null;
    }
    return {
      date: this.dateAndTimeRangeAsDate(dt_obj),
      time: this.dateAndTimeRangeAsTimeRange(dt_obj)
    }
  }

  public get deviceDeliveryDtDate(): Date {
    const dt = this.deviceDeliveryDtDt;
    if (!dt) {
      return null;
    }
    return dt.date;
  }

  public get deviceDeliveryDtTime(): string {
    const dt = this.deviceDeliveryDtDt;
    if (!dt) {
      return null;
    }
    return dt.time;
  }

  public get deviceDeliveryDtFixedDt(): any {
    const dt_obj = this.getFiberScheduleJson('device_delivery_dt_fixed');
    if (!dt_obj) {
      return null;
    }
    return {
      date: this.dateAndTimeRangeAsDate(dt_obj),
      time: this.dateAndTimeRangeAsTimeRange(dt_obj)
    }
  }

  public get deviceDeliveryDtFixedDate(): Date {
    const dt = this.deviceDeliveryDtFixedDt;
    if (!dt) {
      return null;
    }
    return dt.date;
  }

  public get deviceDeliveryDtFixedTime(): string {
    const dt = this.deviceDeliveryDtFixedDt;
    if (!dt) {
      return null;
    }
    return dt.time;
  }

  public updateIsScheduleFormLinkAvailable(): boolean {
    console.log(`updateIsScheduleFormLinkAvailable : `);
    const pay_method = this.getContractPropertyString('PAY METHOD');
    const check_smartpit_deposit = this.getFiberScheduleString('inst_fee_cash_deposit');
    const inst_fee_cash_deposit_value_el = <HTMLInputElement>document.getElementById('inst_fee_cash_deposit_value');
    const inst_fee_cash_deposit_check_el = <HTMLInputElement>document.getElementById('inst_fee_cash_deposit_check');
    let result = true;
    if (pay_method === 'SMARTPIT') {
      inst_fee_cash_deposit_value_el.style.backgroundColor = 'white';
      if (!check_smartpit_deposit || check_smartpit_deposit.length < 0) {
        if (!check_smartpit_deposit || check_smartpit_deposit.length < 0) {
          inst_fee_cash_deposit_value_el.style.backgroundColor = 'red';
          result = false;
        }
      }
      else if(!inst_fee_cash_deposit_check_el.checked) {
        inst_fee_cash_deposit_value_el.style.backgroundColor = 'red';
        result = false;
      }
    }
    return result;
  }

  public updateFiberSchedulePaymentSumFee(): void {
    const targets = ['inst_fee_administration', 'inst_fee_install', 'inst_fee_holiday', 'inst_fee_cash_deposit', null];
    let total = 0;
    targets.forEach(target => {
      if (target !== null) {
        const check = <HTMLInputElement>document.getElementById(`${target}_check`);
        let target_value = "0";
        if (check.checked) {
          target_value = this.getFiberScheduleString(target);
          if (target_value === null) {
            target_value = "0";
          }
          total += parseInt(target_value);
        }
        console.log(`updateFiberSchedulePaymentSumFee : ${target} = ${target_value} : sum(${total})`);
      }
      this.updateIsScheduleFormLinkAvailable();
    });
    console.log(`updateFiberSchedulePaymentSumFee : Total=${total})`)
    const dispTarget1 = document.getElementById('fiber_schedule_fee_total_no_tax');
    dispTarget1.innerText = total.toString();
    const dispTarget2 = document.getElementById('fiber_schedule_fee_total_with_tax');
    dispTarget2.innerText = Math.floor(total * 1.10).toString();
  }

  public updateFiberSchedulePaymentSumStatus(): void {
    const targets = ['inst_fee_administration', 'inst_fee_install', 'inst_fee_holiday', 'inst_fee_cash_deposit'];
    const pay_method = this.getContractPropertyString("PAY METHOD");
    targets.forEach(target => {
      const check = <HTMLInputElement>document.getElementById(`${target}_check`);
      if (check.checked) {
        if (pay_method === 'SMARTPIT') {
          this.putFiberScheduleValueChange(`${target}_status`, '請求中', target);
        }
      }
    });
    if (pay_method === 'SMARTPIT') {
      const target = <HTMLInputElement>document.getElementById(`inst_fee_pay_status_selected`);
      this.putFiberScheduleValueChange(`inst_fee_pay_status`, '請求中', target);
    }
  }

  public setFiberSchedulePaymentFee(field: string, event: any): void {
    let item = this.getFiberScheduleJson(field);
    if (!item) {
      item = {
        "fee": null,
        "status": null
      }
    }
    item.fee = parseInt(event.srcElement.value);
    console.log(`setFiberPaymentFee : `, item);
    this.putFiberScheduleValueChange(field, JSON.stringify(item), event.srcElement);
    this.updateFiberSchedulePaymentSumFee();
  }

  public getFiberSchedulePaymentFee(field: string): string {
    const item = this.getFiberScheduleJson(field);
    if (!item || !item.fee) {
      return null;
    }
    return item.fee;
  }

  public setFiberSchedulePaymentStatus(field: string, event: any): void {
    let item = this.getFiberScheduleJson(field);
    if (!item) {
      item = {
        "fee": null,
        "status": null
      }
    }
    item.status = event.srcElement.value;
    this.putFiberScheduleValueChange(field, JSON.stringify(item), event.srcElement);
    this.updateFiberSchedulePaymentSumStatus();
  }

  public getFiberSchedulePaymentStatus(field: string): string {
    const item = this.getFiberScheduleJson(field);
    if (!item || !item.status) {
      return "未請求";
    }
    return item.status;
  }

  public getFiberScheduleDateCandidateDt(field: string, index: number): any {
    if (!this.data || !this.data.fiber_schedule) {
      return;
    }
    let value = JSON.parse(this.data.fiber_schedule[field]);
    if (!value || !Array.isArray(value)) {
      value = [];
      for (let i = 0; i < 10; i++) {
        value.push({ date: null, time: null });
      }
      this.data.fiber_schedule[field] = JSON.stringify(value);
    }
    if (value.length - 1 < index) {
      for (let i = value.length - 1; i < 10; i++) {
        value.push({ date: null, time: null });
      }
      this.data.fiber_schedule[field] = JSON.stringify(value);
    }
    return value[index];
  }

  public getFiberScheduleDateAsString(field: string): string {
    const date_string = this.getFiberScheduleString(field);
    return Utils.getDisplayDatetimeString(date_string);
  }

  public getFiberScheduleDtDate(field: string): Date {
    const item = this.getFiberScheduleJson(field);
    if (!item) {
      return null;
    }
    return item.date;
  }

  public getFiberScheduleDtTime(field: string): Date {
    const item = this.getFiberScheduleJson(field);
    if (!item) {
      return null;
    }
    return item.time;
  }

  public getFiberScheduleDateCandidateDtDate(field: string, index: number): Date {
    const item = this.getFiberScheduleDateCandidateDt(field, index);
    if (!item) {
      return null;
    }
    return item.date;
  }

  public getFiberScheduleDateCandidateDtTime(field: string, index: number): String {
    const item = this.getFiberScheduleDateCandidateDt(field, index);
    if (!item) {
      return null;
    }
    return item.time;
  }

  public setFiberScheduleDateCandidateDt(field: string, index: number, value: any): void {
    if (!this.data || !this.data.fiber_schedule) {
      return;
    }
    if (!this.data.fiber_schedule[field]) {
      this.data.fiber_schedule[field] = [];
    }
    if (this.data.fiber_schedule[field].length - 1 < index) {
      return;
    }
    this.data.fiber_schedule[field][index] = JSON.stringify(value);
    this.putFiberScheduleValueChange(field, this.data.fiber_schedule[field], null);
  }

  public updatefiberScheduleStatusCandidates(field: string, candidates: any): void {
    // const new_candidates = candidates;
    const new_candidates = candidates.filter(candidate => {
      if (candidate && candidate.date && (candidate.time && candidate.time != '---')) {
        return true;
      }
      return false;
    });
    this.data.fiber_schedule[field] = JSON.stringify(new_candidates);
  }

  public setFiberScheduleDateCandidateDtDate(field: string, index: number, event: any): void {
    console.log(`setFiberScheduleDateCandidateDtDate : ${field}, ${index}`);
    let current = this.getFiberScheduleDateCandidateDt(field, index);
    current.date = Utils.dateToDateString(event.value);
    let candidates = JSON.parse(this.data.fiber_schedule[field]);
    candidates[index] = current;
    if (current.date && (current.time && current.time != '---')) {
      this.updatefiberScheduleStatusCandidates(field, candidates);
      this.putFiberScheduleValueChange(field, this.data.fiber_schedule[field], event.targetElement);
    }
    else {
      this.data.fiber_schedule[field] = JSON.stringify(candidates);
    }
  }

  public setFiberScheduleDateCandidateDtTime(field: string, index: number, event: any): void {
    console.log(`setFiberScheduleDateCandidateDtTime : ${field}, ${index}`);
    let current = this.getFiberScheduleDateCandidateDt(field, index);
    current.time = event.srcElement.value;
    let candidates = JSON.parse(this.data.fiber_schedule[field]);
    candidates[index] = current;
    if (current.date && (current.time && current.time != '---')) {
      this.updatefiberScheduleStatusCandidates(field, candidates);
      this.putFiberScheduleValueChange(field, this.data.fiber_schedule[field], event.srcElement);
    }
    else {
      this.data.fiber_schedule[field] = JSON.stringify(candidates);
    }
  }

  public clearFiberScheduleDateCandidateDt(field: string, index: number, event: any) {
    console.log(`clearFiberScheduleDateCandidateDt : ${field}, ${index}`);
    let candidates = JSON.parse(this.data.fiber_schedule[field]);
    if (index < 9) {
      candidates[index] = candidates[index + 1];
      candidates[index + 1] = null;
    }
    else {
      candidates[index] = null;
    }
    this.updatefiberScheduleStatusCandidates(field, candidates);
    this.putFiberScheduleValueChange(field, this.data.fiber_schedule[field], event.srcElement);
  }

  public onClickExportSmartPitCSV(event: any): void {
    console.log('onClickExportSmartPitCSV', this.data);
    const dispTarget1 = document.getElementById('fiber_schedule_fee_total_no_tax');
    const dispTarget2 = document.getElementById('fiber_schedule_fee_total_with_tax');
    const sub_total = parseInt(dispTarget1.innerText, 10);
    const with_vat = parseInt(dispTarget2.innerText, 10);
    const vat = with_vat - sub_total;
    const utf8_string = this.generateRecordForSmartpit({
      contract_code: this.data.contract.contract_code,
      smartpit_number: this.getContractPropertyString("SMARTPIT NUMBER"),
      sub_total: sub_total.toString(),
      sub_total_tax: vat.toString(),
    });
    // CSV : ダウンロード
    const file = new Blob([utf8_string], { type: 'text/csv;charset=utf-8' });
    const a = document.createElement('a');
    a.download = `1271400000.csv`
    // a.download = `fiber-smartpit-${this.data.contract.contract_code}.csv`
    a.href = window.URL.createObjectURL(file);
    a.click();

    // 反映 : 

    const inst_fee_administration_check_el = <HTMLInputElement>document.getElementById('inst_fee_administration_check');
    const inst_fee_install_check_el = <HTMLInputElement>document.getElementById('inst_fee_install_check');
    const inst_fee_holiday_check_el = <HTMLInputElement>document.getElementById('inst_fee_holiday_check');
    const inst_fee_cash_deposit_check_el = <HTMLInputElement>document.getElementById('inst_fee_cash_deposit_check');

    const pay_for = [];

    if (inst_fee_administration_check_el.checked) {
      pay_for.push('inst_fee_administration');
    }
    if (inst_fee_install_check_el.checked) {
      pay_for.push('inst_fee_install');
    }
    if (inst_fee_holiday_check_el.checked) {
      pay_for.push('inst_fee_holiday');
    }

    if (inst_fee_cash_deposit_check_el.checked) {
      pay_for.push('inst_fee_cash_deposit');
    }

    const param = {
      pay_for: pay_for,
      contract_product_id: this.data.contract_product.id,
    }

    this.auth_service.apiPostFiberChargedSmartpit(param).subscribe(
      response => {
        if (inst_fee_administration_check_el.checked) {
          this.data.fiber_schedule.inst_fee_administration_status = '請求中';
        }
        if (inst_fee_install_check_el.checked) {
          this.data.fiber_schedule.inst_fee_install_status = '請求中';
        }
        if (inst_fee_holiday_check_el.checked) {
          this.data.fiber_schedule.inst_fee_holiday_status = '請求中';
        }
        if (inst_fee_cash_deposit_check_el.checked) {
          this.data.fiber_schedule.inst_fee_cash_deposit_status = '請求中';
        }
        this.data.fiber_schedule.inst_fee_pay_status = '請求中';
        event.srcElement.classList.remove('error');
        this.snackBarSuccess("Update succeeded", 'OK');
      },
      error => {
        event.srcElement.classList.add('error');
        if (!error.error || !error.error.message) {
          if (error.message) {
            this.snackBarError(error.message, 'OK');
          }
          else {
            this.snackBarError("Unhandled error", 'OK');
          }
        }
        else {
          this.snackBarError(error.error.message.message, 'OK');
        }
      }
    )

    this.updateFiberSchedulePaymentSumStatus();
  }

  public onClickChargeCredit(event: any): void {
    console.log('onClickChargeCredit', this.data);

    const total_el = document.getElementById('fiber_schedule_fee_total_no_tax').innerText;

    const inst_fee_administration_check_el = <HTMLInputElement>document.getElementById('inst_fee_administration_check');
    const inst_fee_install_check_el = <HTMLInputElement>document.getElementById('inst_fee_install_check');
    const inst_fee_holiday_check_el = <HTMLInputElement>document.getElementById('inst_fee_holiday_check');

    const pay_for = [];

    if (inst_fee_administration_check_el.checked) {
      pay_for.push('inst_fee_administration');
    }
    if (inst_fee_install_check_el.checked) {
      pay_for.push('inst_fee_install');
    }
    if (inst_fee_holiday_check_el.checked) {
      pay_for.push('inst_fee_holiday');
    }

    const param = {
      pay_for: pay_for,
      contract_product_id: this.data.contract_product.id,
      charge_total: parseInt(total_el)
    }
    this.auth_service.apiPostFiberChargeCredit(param).subscribe(
      response => {
        if (inst_fee_administration_check_el.checked) {
          this.data.fiber_schedule.inst_fee_administration_status = '支払済';
        }
        if (inst_fee_install_check_el.checked) {
          this.data.fiber_schedule.inst_fee_install_status = '支払済';
        }
        if (inst_fee_holiday_check_el.checked) {
          this.data.fiber_schedule.inst_fee_holiday_status = '支払済';
        }
        this.data.fiber_schedule.inst_fee_pay_status = '支払済';
        event.srcElement.classList.remove('error');
        this.snackBarSuccess("Update succeeded", 'OK');
      },
      error => {
        event.srcElement.classList.add('error');
        if (!error.error || !error.error.message) {
          if (error.message) {
            this.snackBarError(error.message, 'OK');
          }
          else {
            this.snackBarError("Unhandled error", 'OK');
          }
        }
        else {
          this.snackBarError(error.error.message.message, 'OK');
        }
      }
    )
  }

  public onUpdateFixedSchedule(): void {
    console.log(`onUpdateFixedSchedule : contract_product_id=${this.data.contract_product.id}`);
    const param = {
      contract_product_id: this.data.contract_product.id
    }
    this.auth_service.apiPostUpdateFixedSchedule(param).subscribe(
      response => {
        this.snackBarSuccess("Schedule PDF was generated.", 'OK');
      },
      error => {
        if (!error.error || !error.error.message) {
          if (error.message) {
            this.snackBarError(error.message, 'OK');
          }
          else {
            this.snackBarError("Failed to generate Schedule PDF.", 'OK');
          }
        }
        else {
          this.snackBarError(error.error.message.message, 'OK');
        }
      }
    )
  }

  public get fixedScheduleLink(): string {
    return `${environment.apiRoot}/FS_${this.data.contract.contract_hash}.pdf`
  }

  private snackBarSuccess(message: string, button: string): void {
    console.log(`snackBarSuccess ${message}, ${button}`);
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 3000;
    cfg.panelClass = ['notify_snackbar', 'success'];
    this.snackBar.open(message, button, cfg);
  }

  private snackBarError(message: string, button: string): void {
    console.log(`snackBarError ${message}, ${button}`);
    this.snackBar.dismiss();
    const cfg = new MatSnackBarConfig();
    cfg.duration = 5000;
    cfg.panelClass = ['notify_snackbar', 'error'];
    this.snackBar.open(message, button, cfg);
  }

  private generateRecordForSmartpit(param: any): string {
    console.log('FiberSchedule::generateRecordForSmartpit ');
    const first_pay_day = new Date();
    first_pay_day.setDate(first_pay_day.getDate() + 7);
    const csv_record =
      // 30固定
      '30,' +
      // 00固定
      '00,' +
      // 空セル
      ',' +
      // 空セル
      ',' +
      // 1271400000固定
      '1271400000,' +
      // スマピ番号(13桁)
      param.smartpit_number +
      ',' +
      // 空セル
      ',' +
      // 空セル
      ',' +
      // BookingID-FIBER-INIT
      param.contract_code + '-FI' +
      ',' +
      // BookingID-FIBER-INIT
      param.contract_code + '-FI' +
      ',' +
      // 月額料金
      param.sub_total +
      ',' +
      // 税 : 消費税 : V.A.T : TAX
      param.sub_total_tax +
      ',' +
      // 支払い第一締切日(翌月10日)
      first_pay_day.getFullYear().toString() + (first_pay_day.getMonth() + 1).toString().padStart(2, '0') + first_pay_day.getDate().toString().padStart(2, '0') +
      ',' +
      // 0固定 (0:支払い期日延長なし / 1:支払い期日自動延長)
      '0,' +
      // 月額料金+500円
      ',' +
      // 税(+500円の税) : 消費税 : V.A.T : TAX (500 * 10%)
      ',' +
      // 支払い第二締切日(翌月25日)
      ',' +
      // 空セル
      ',,,,,\r\n';
    console.log('generated : ' + csv_record);
    return csv_record;
  }
}
