import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/page/login/login.component';
import { SignupComponent } from './components/page/signup/signup.component';
import { PortalComponent } from './components/page/portal/portal.component';
import { OperationComponent } from './components/page/operation/operation.component';
import { ContractsComponent } from './components/page/contracts/contracts.component';
import { ContractComponent } from './components/page/contract/contract.component';
import { ContractPrintComponent } from './components/page/contract-print/contract-print.component';
import { InvoicesComponent } from './components/page/invoices/invoices.component';
import { InvoiceComponent } from './components/page/invoice/invoice.component';
import { FileImportComponent } from './components/page/file-import/file-import.component';
import { DashboardComponent } from './components/page/dashboard/dashboard.component';
import { AdminToolComponent } from './components/page/admin-tool/admin-tool.component';
import { ParentalConsentComponent } from './components/page/parental-consent/parental-consent.component';
import { ReferralPointsComponent } from './components/page/refferal-points/refferal-points.component';
import { FiberScheduleComponent } from './components/page/fiber-schedule/fiber-schedule.component';
import { ListActivateComponent } from './components/list/list-activate/list-activate.component';

const routes: Routes = [
  // { path: 'auth/signup', component: SignupComponent },
  // Rails : sign_up, password_confirmation, resend_instructions
  { path: 'auth/login', component: LoginComponent },
  { path: 'portal', component: PortalComponent },
  { path: 'operation', component: OperationComponent },
  { path: 'operation/contract', component: ContractComponent },
  { path: 'operation/contract_print', component: ContractPrintComponent },
  { path: 'operation/invoice', component: InvoiceComponent },
  { path: 'operation/admin_tool', component: AdminToolComponent },
  { path: 'operation/woo_point_export', component: ReferralPointsComponent },
  { path: 'operation/parental_consent', component: ParentalConsentComponent },
  { path: 'operation/fiber_schedule', component: FiberScheduleComponent },
  { path: 'operation/list-activate', component: ListActivateComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
export const routingComponents = [
  LoginComponent,
  SignupComponent,
  PortalComponent,
  OperationComponent,
  AdminToolComponent,
];
